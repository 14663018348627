import request from './base';

export async function getPatientNames({ user }) {
  return await request({
    endpoint: '/patient/patient-names',
    method: 'GET',
    user,
  })
}

export async function getMostRecentPatients({ user, search='' }) {
  return await request({
    endpoint: '/patient/most-recent-patients',
    method: 'GET',
    user,
    queryParams: {
      search
    }
  })
}

export async function getPatients({ user, pageSize=10, currentPage=0, sortBy=undefined, asc=true, search='' }) {
  return await request({
    endpoint: '/patient/',
    method: 'GET',
    user,
    queryParams: {
      page_size: pageSize,
      current_page: currentPage,
      sort_by: sortBy,
      asc: asc ? 'asc' : 'desc',
      search
    }
  })
}

export async function addPatient({ user, patientData }) {
  return await request({
    endpoint: '/patient/',
    method: 'POST',
    user,
    body: patientData,
  })
}

export async function deletePatient({ user, patientId }) {
  return await request({
    endpoint: `/patient/${patientId}`,
    method: 'DELETE',
    user,
  })
}

export async function editPatient({ user, patientData }) {
  const {patientId, ...props} = patientData
  return await request({
    endpoint: `/patient/${patientId}`,
    method: 'PUT',
    user,
    body: props,
  })
}

export async function getOldPatientsWithNotes({ user }) {
  return await request({
    endpoint: '/patient/old-patients-with-notes',
    method: 'GET',
    user,
  })
}
