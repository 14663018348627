import request from './base';

export async function getPatientNotes({ user, patientId, page=0, pageSize=10, search='' }) {
  return await request({
    endpoint: `/notes/${patientId}`,
    method: 'GET',
    queryParams: { page, page_size: pageSize, search },
    user,
  })
}

export async function addOrUpdatePatientNote({ user, patientId, note }) {
  return await request({
    endpoint: `/notes/${patientId}`,
    method: 'POST',
    user,
    body: note,
  })
}

export async function deletePatientNote({ user, patientId, creationTime }) {
  return await request({
    endpoint: `/notes/${patientId}`,
    method: 'DELETE',
    user,
    queryParams: { creation_time: creationTime },
  })
}

export async function deleteAllPatientNotes({ user, patientId }) {
  return await request({
    endpoint: `/notes/${patientId}`,
    method: 'DELETE',
    user,
  })
}

export async function swapPinNote({ user, patientId, creationTime }) {
  return await request({
    endpoint: `/notes/swap-pin/${patientId}`,
    method: 'POST',
    user,
    body: { creation_time: creationTime },
  })
}
