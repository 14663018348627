import React, { useEffect, useState, useMemo } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import {
  FaCopy,
  FaSearch,
  FaSignInAlt,
  FaTrash,
  FaUserEdit,
  FaWhatsapp,
} from 'react-icons/fa'

import dummySessions from './dummySessions'
import Input from '../material-forms/Input'

const defaultColumns = [
  {
    name: 'Fecha',
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: 'Paciente',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Duración',
    selector: (row) => row.duration,
    sortable: true,
  },
  {
    name: 'Concepto',
    selector: (row) => row.concept,
    sortable: true,
  },
  {
    name: 'Notas rápidas',
    selector: (row) => row.notes,
    sortable: true,
  },
  {
    name: 'Acción',
    cell: () => (
      <div>
        <Button size="sm" className="mx-1 my-1 btn-success">
          <FaSignInAlt className="mx-1 mb-1" />
        </Button>
        <Button size="sm" className="mx-1 my-1">
          <FaCopy className="mx-1 mb-1" />
        </Button>
        <Button size="sm" className="mx-1 my-1 btn-success">
          <FaWhatsapp className="mx-1 mb-1" />
        </Button>
        <Button size="sm" className="mx-1 my-1">
          <FaUserEdit className="mx-1 mb-1" />
        </Button>
        <Button size="sm" className="mx-1 my-1 btn-danger">
          <FaTrash className="mx-1 mb-1" />
        </Button>
      </div>
    ),
  },
]

// https://react-data-table-component.netlify.app/?path=/docs/api-custom-themes--page
createTheme('bp', {
  background: {
    default: 'transparent',
  },
  text: {
    primary: 'var(--bp-dark-rgb)',
    secondary: 'rgba(var(--bp-dark-rgb), 0.54)',
  },
  divider: {
    default: 'rgba(var(--bp-dark-rgb), 0.25)',
  },
  button: {
    default: 'rgba(var(--bp-dark-rgb),.54)',
    focus: 'rgba(var(--bp-dark-rgb),.12)',
    hover: 'rgba(var(--bp-dark-rgb),.12)',
    disabled: 'rgba(var(--bp-dark-rgb), .18)',
  },
})

export default function Table({
  title = 'Movies',
  data = dummySessions,
  status = 'success',
  columns = defaultColumns,
  totalRows = undefined,
  onChangePage = undefined,
  onChangeRowsPerPage = undefined,
  onChangeSearch = undefined,
  onSort = undefined,
  extraButtons = undefined,
  pagination = true,
  ...props
}) {
  const [filterText, setFilterText] = useState('')

  let filteredItems = data
  if (!onChangeSearch) {
    filteredItems = filteredItems.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1,
    )
  }

  const memoSearch = useMemo(() => {
    return (
      <Input
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        placeholder={
          <>
            <FaSearch className="mx-1 small" /> Buscar
          </>
        }
        groupStyle={{
          margin: '45px 0',
        }}
      />
    )
  }, [filterText])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (onChangeSearch) onChangeSearch(filterText)
    }, 250)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText])

  return (
    <>
      {status === 'loading' && (
        <>
          <Row>
            <Col style={{ marginTop: 44 }}>
              <span style={{ fontSize: 22, marginLeft: 15 }}>{title}</span>
            </Col>
          </Row>
          <Row className="text-center justify-content-center">
            <Col className="" style={{ textAlign: 'right' }}>
              <div className="spinner-border text-primary" role="status" />
            </Col>
            <Col
              className="sr-only pt-1"
              style={{ textAlign: 'left', fontSize: 22, marginTop: -5 }}
            >
              <i className="text-secondary">Cargando ...</i>
            </Col>
          </Row>
        </>
      )}
      {status === 'error' && (
        <>
          <Row>
            <Col style={{ marginTop: 44 }}>
              <span style={{ fontSize: 22, marginLeft: 15 }}>{title}</span>
            </Col>
          </Row>
          <Row className="text-center justify-content-center">
            <Col
              className="sr-only pt-1"
              style={{ textAlign: 'center', fontSize: 22, marginTop: -5 }}
            >
              <i className="text-secondary">Ha ocurrido un error</i>
            </Col>
          </Row>
        </>
      )}
      {status === 'success' && (
        <Row>
          <Col md={12}>
            <DataTable
              title={
                <Row>
                  <Col className="mt-5" lg={9} md={8} xs={12}>
                    {title}
                  </Col>
                  <Col lg={3} md={4} xs={7}>
                    {extraButtons !== undefined ? (
                      <Row>
                        <Col md={10}>{memoSearch}</Col>
                        <Col md={2}>{extraButtons}</Col>
                      </Row>
                    ) : (
                      memoSearch
                    )}
                  </Col>
                </Row>
              }
              columns={columns}
              data={filteredItems}
              defaultSortFieldID={1}
              noDataComponent={
                <div className="mt-4 text-secondary">
                  No hay datos con los filtros seleccionados
                </div>
              }
              theme="bp"
              progressPending={status !== 'success'}
              pagination={pagination}
              paginationServer={totalRows !== null}
              paginationTotalRows={totalRows}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onSort={onSort}
              sortServer={onSort !== undefined}
              {...props}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
