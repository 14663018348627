import React, { useState } from 'react'

export default function Select({
  children,
  defaultValue = null,
  className = '',
  label = 'Select',
  onChange = () => {},
  value = undefined,
  ...props
}) {
  const [isSelected, setIsSelected] = useState(
    defaultValue !== null || value !== undefined,
  )

  const extraProps = {}
  if (defaultValue !== null) extraProps.defaultValue = defaultValue
  if (value !== undefined) extraProps.value = value
  extraProps.value = value

  return (
    <div className="select w-100 my-3 my-lg-0">
      <select
        className={`select-text bp-text-dark w-100 ${className} ${
          isSelected ? 'option-selected' : ''
        }`}
        onChange={(evt) => {
          setIsSelected(true)
          onChange(evt)
        }}
        {...extraProps}
        {...props}
      >
        <option hidden></option>
        {children}
      </select>
      <span className="select-highlight"></span>
      <span className="select-bar"></span>
      <label className="select-label">{label}</label>
    </div>
  )
}
