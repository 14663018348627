import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

import Input from '../material-forms/Input'
import TextArea from '../material-forms/TextArea'

import { useAuth } from '../../contexts/AuthContext'
import { addPatient } from '../../services/patient'

export default function AddPatient({ ...props }) {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return (
    <>
      <Row className="mx-sm-5 mx-2 mt-2" {...props}>
        <Col lg={12}>
          <h5>Añadir nuevo paciente</h5>
        </Col>
      </Row>
      <Form
        onSubmit={async (e) => {
          e.preventDefault()
          const patientData = {
            dni: e.target.dni.value,
            name: e.target.name.value,
            last_name: e.target.lastName.value,
            alias: e.target.alias.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            default_price: e.target.defaultPrice.value,
            notes: e.target.subject.value,
          }

          // Ensure all required fields are filled
          for (const key in patientData) {
            if (key === 'alias' || key === 'email' || key === 'notes' || key === 'default_price') continue
            if (patientData[key] === '') {
              NotificationManager.error(
                'Por favor, rellena todos los campos requeridos',
                'Error',
              )
              return
            }
          }

          const response = await addPatient({ user, patientData })
          if (response.ok) {
            NotificationManager.success(
              'Paciente añadido correctamente',
              'Éxito',
            )
            queryClient.invalidateQueries('patients')
            queryClient.invalidateQueries('patientNames')
            queryClient.invalidateQueries('patientsCountSessions')
            e.target.reset()
          } else {
            NotificationManager.error(
              'Ha ocurrido un error al añadir el paciente',
            )
          }
        }}
      >
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">
              DNI <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="dni" type="text" placeholder={'DNI'} />
          </Col>
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">
              Nombre <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="name" type="text" placeholder={'Nombre'} />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">
              Apellidos <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="lastName" type="text" placeholder={'Apellidos'} />
          </Col>
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">Alias</div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="alias" type="text" placeholder={'Alias'} />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">Email</div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="email" type="email" placeholder={'Email'} />
          </Col>
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">
              Teléfono <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="phone" type="text" placeholder={'Teléfono'} />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3">Precio</div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input name="defaultPrice" type="number" placeholder={'Precio por defecto del paciente'} />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 9 }}>
            <TextArea
              name="subject"
              placeholder="Notas rápidas sobre el paciente"
            />
          </Col>
        </Row>
        <Row className="my-4 mx-md-5 mx-2">
          <Col
            lg={{ offset: 5, span: 2 }}
            md={{ offset: 4, span: 4 }}
            sm={{ offset: 2, span: 8 }}
          >
            <Button type="submit" className="bp-btn">
              Añadir paciente
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
