import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { FaTrash, FaUserEdit } from 'react-icons/fa'

import Table from './Table'
import { useAuth } from '../../contexts/AuthContext'
import { getPatients, deletePatient } from '../../services/patient'
import EditPatient from '../add-patient/EditPatient'
import Confirm from '../notification/Confirm'
import Modal from '../modal/Modal'

export default function PatientsTable() {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const [patientToEdit, setPatientToEdit] = useState(null)

  const dateToString = (row_date) => {
    const date = new Date(row_date.replace(' GMT', ''))
    return (
      `${date.getDate()} ${date.toLocaleString('default', {
        month: 'short',
      })} ${date.getFullYear()}` +
      ` ${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
    )
  }

  const PATIENTS_COLUMNS = [
    {
      name: 'Última sesión',
      selector: (row) =>
        row.last_session === 'Nunca' ? 'Nunca' : dateToString(row.last_session),
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row) => `${row.name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.mail,
      sortable: true,
    },
    {
      name: 'Teléfono',
      selector: (row) => (row?.phone ?? '').replace(' ', ''),
      sortable: true,
    },
    {
      name: 'Notas rápidas',
      selector: (row) => row.notes,
      sortable: true,
    },
    {
      name: 'Deuda',
      selector: (row) => `${row.debt}€`,
      sortable: true,
    },
    {
      name: 'Acción',
      cell: (row) => (
        <div>
          <Button
            onClick={() => {
              setPatientToEdit(row)
            }}
            size="sm"
            className="mx-1 my-1 btn-modify emphasize-on-hover"
          >
            <FaUserEdit className="mx-1 mb-1" />
          </Button>
          <Button
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de eliminar los datos del paciente{' '}
                      {row.patient_name} {row.patient_last_name}?
                    </span>
                  }
                  onClick={async () => {
                    const response = await deletePatient({
                      user,
                      patientId: row.id,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('patients')
                      queryClient.invalidateQueries('patientNames')
                      queryClient.invalidateQueries('patientsCountSessions')
                      NotificationManager.info(
                        'Paciente eliminado correctamente',
                      )
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
            size="sm"
            className="mx-1 mx-lg-3 my-1 btn-danger emphasize-on-hover"
          >
            <FaTrash className="mx-1 mb-1" />
          </Button>
        </div>
      ),
    },
  ]

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState(undefined)
  const [asc, setAsc] = useState(true)

  const { data, status } = useQuery(
    ['patients', page, search, sortBy, pageSize, asc],
    async () => {
      const params = {
        user,
        currentPage: page - 1,
        search: search,
        sortBy: sortBy,
        pageSize: pageSize,
        asc: asc,
      }

      const response = await getPatients(params)
      return {
        patients: response.patients,
        totalRows: response.total_rows,
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  return (
    <>
      <Modal
        show={patientToEdit !== null}
        onHide={() => {
          setPatientToEdit(null)
        }}
      >
        <EditPatient
          patient={patientToEdit}
          onSubmit={() => setPatientToEdit(null)}
        />
      </Modal>
      <Table
        title={'Pacientes'}
        data={data?.patients}
        status={status}
        columns={PATIENTS_COLUMNS}
        totalRows={data?.totalRows}
        onChangePage={(page) => {
          setPage(page)
        }}
        onChangeRowsPerPage={(newPerPage, page) => {
          setPageSize(newPerPage)
          setPage(page)
        }}
        onChangeSearch={(newSearch) => {
          setSearch(newSearch)
          setPage(1)
        }}
        onSort={(column, direction) => {
          switch (column.name) {
            case 'Última sesión':
              setSortBy('last_session')
              break
            case 'Nombre':
              setSortBy('name_and_last_name')
              break
            case 'Email':
              setSortBy('email')
              break
            case 'Concepto':
              setSortBy('concept')
              break
            case 'Notas rápidas':
              setSortBy('notes')
              break
            case 'Deuda':
              setSortBy('debt')
              break
            default:
              setSortBy(undefined)
          }
          setAsc(direction === 'asc')
          setPage(1)
        }}
      />
    </>
  )
}
