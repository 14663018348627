import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

import { dateToString } from '../../services/utils'
import { getSession } from '../../services/session'
import { handleJoinSession } from '../../services/session'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24

export default function ClientSession({ sessionId, checksum }) {
  const queryClient = useQueryClient()
  const { data, status } = useQuery(
    ['session', sessionId, checksum],
    async () => {
      const response = await getSession({ sessionId, checksum })
      return response
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (status !== 'success' || !data) return

    const timeToLaunch = currentDate - new Date(data.date.replace(' GMT', ''))
    if (timeToLaunch < 0) {
      return
    }

    // Invalidate queries when the next session is over
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries('nextSession')
      queryClient.invalidateQueries('nextSessions')
      queryClient.invalidateQueries('previousSessions')
    }, timeToLaunch)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status, currentDate])

  if (status !== 'success' || !data) {
    return <></>
  }

  const durationToStr = (duration) => {
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60
    if (hours === 0) return `${minutes}m`
    else if (minutes === 0) return `${hours}h`
    return `${hours}h ${minutes}m`
  }

  const redirectToSession = () => {
    if (distance > 10 * MINUTE) {
      NotificationManager.info(
        'Podrás acceder a la sala 10 minutos antes de que ' +
          'empiece tu sesión, así no tendrás nada de que preocuparte.',
        '¿Con ganas de empezar?',
      )
      return
    }
    handleJoinSession({ session: sessionId, checksum })
  }

  const date = new Date(data.date.replace(' GMT', ''))
  const distance = date - currentDate
  const days = Math.floor(distance / DAY)
  const hours = Math.floor((distance % DAY) / HOUR)
  const minutes = Math.floor((distance % HOUR) / MINUTE)
  const seconds = Math.floor((distance % MINUTE) / SECOND)

  return (
    <Container>
      <Row className="justify-content-md-center mx-3">
        <Col
          xl={4}
          lg={6}
          className="bp-bg-light bp-text-dark my-3"
          style={{ borderRadius: '15px' }}
        >
          <Row align="center" className="mx-3 my-3">
            <h4>Accede a tu sesión</h4>
            <p>
              Hola {data.patient_name}, estamos trabajando en tu sesión del{' '}
              <strong>{dateToString(date)}</strong> para que tengas la mejor
              experiencia posible.
              {distance > 0 ? (
                <>
                  <br />
                  <strong>¡Ya falta poco!</strong>
                </>
              ) : (
                <></>
              )}
            </p>
          </Row>
          <Row align="center" className="mx-3">
            <Col>
              {distance <= 0 ? (
                <span className="h5">
                  La sesión ya ha comenzado, por favor no te retrases en entrar.
                </span>
              ) : (
                <>
                  {days > 0 && (
                    <>
                      <span className="h5">{days}</span>{' '}
                      <span>día{days > 1 ? 's' : ''}, </span>
                    </>
                  )}
                  {hours > 0 && (
                    <>
                      <span className="h5">{hours}</span>{' '}
                      <span>hora{hours > 1 ? 's' : ''}, </span>
                    </>
                  )}
                  <span className="h5">{minutes}</span>{' '}
                  <span>minuto{minutes !== 1 ? 's' : ''} y </span>
                  <span className="h5">{seconds}</span>{' '}
                  <span>segundo{seconds !== 1 ? 's' : ''}</span>
                </>
              )}
            </Col>
          </Row>
          <Row align="center" className="my-4 mx-md-5">
            <Col md={12}>
              <Button
                onClick={redirectToSession}
                onMouseDown={(e) => {
                  if (e.button === 1) {
                    redirectToSession()
                  }
                }}
                className={`bp-btn ${
                  distance > 10 * MINUTE ? 'opacity-50' : ''
                }`}
              >
                Entrar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-md-center mx-3">
        <Col xl={4} lg={6} className="my-3 text-center">
          <p className="text-secondary">
            Tu sesión está planificada para durar{' '}
            <strong>{durationToStr(data.duration)}</strong> pero con el fin de
            ofrecer el mejor servicio puede alargarse hasta
            <strong> {durationToStr(data.duration + 30)}</strong> si el caso lo
            requiere.
          </p>
          <span className="text-secondary">
            Te agradecemos que llegues puntual. Si no puedes asistir, por favor,
            avísanos con 24 horas de antelación para poder reasignar tu cita.
          </span>
        </Col>
      </Row>
    </Container>
  )
}
