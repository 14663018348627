import React from 'react'
import ReactDOM from 'react-dom/client'
import { NotificationContainer } from 'react-notifications'

import './index.css'
import 'react-notifications/lib/notifications.css';

import App from './App'
import { AuthProvider } from './contexts/AuthContext'
import reportWebVitals from './reportWebVitals'

import ThemeContextWrapper from './theme/ThemeWrapper'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeContextWrapper>
      <AuthProvider>
        <NotificationContainer />
        <App />
      </AuthProvider>
  </ThemeContextWrapper>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
