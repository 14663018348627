import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FaSearch, FaRegStickyNote } from 'react-icons/fa'
import { NotificationManager } from 'react-notifications'
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query'

import useDebounce from '../../hooks/UseDebounce'

import { useAuth } from '../../contexts/AuthContext'
import Confirm from '../../components/notification/Confirm'
import Note from '../../components/note/Note'
import PatientNotes from '../../components/note/PatientNotes'
import Input from '../../components/material-forms/Input'
import { dateToString } from '../../services/utils'

import {
  getMostRecentPatients,
  getOldPatientsWithNotes,
} from '../../services/patient'
import {
  getPatientNotes,
  addOrUpdatePatientNote,
  deleteAllPatientNotes,
  deletePatientNote,
  swapPinNote,
} from '../../services/note'

export default function Notes() {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState('')
  const [searchNote, setSearchNote] = useState('')
  const searchParamsPatientId = parseInt(searchParams.get('patientId'))
  const [selectedPatientId, setSelectedPatientId] = useState(
    isNaN(searchParamsPatientId) ? null : searchParamsPatientId,
  )
  const searchNoteDebounced = useDebounce(searchNote, 250)

  const { data: patients, status } = useQuery(
    ['mostRecentPatients', search],
    async () => {
      const response = await getMostRecentPatients({ user, search })
      if (!response.ok) return []

      const transformedData = response.patients.map((patient) => {
        const lastSession =
          patient.last_session === 'Nunca'
            ? 'Nunca'
            : dateToString(
                new Date(patient.last_session.replace(' GMT', '') ?? ''),
              )
        return {
          id: patient.id,
          name: patient.name + ' ' + patient.last_name,
          lastSession: lastSession,
        }
      })

      return transformedData
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  const { data: oldPatients } = useQuery(
    'oldPatientsWithNotes',
    async () => {
      const response = await getOldPatientsWithNotes({ user })
      if (!response.ok) return []

      const transformedData = response.patients.map((patient) => {
        const lastSession =
          patient.last_session === 'Nunca'
            ? 'Nunca'
            : dateToString(
                new Date(patient.last_session.replace(' GMT', '') ?? ''),
              )
        return {
          id: patient.id,
          name: patient.name + ' ' + patient.last_name,
          lastSession: lastSession,
        }
      })

      return transformedData
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  const {
    data: notesPages,
    status: notesStatus,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['notes', selectedPatientId, searchNoteDebounced],
    async ({
      pageParam = {
        nextPage: 0,
        maxPages: undefined,
        searchNote: searchNoteDebounced,
      },
    }) => {
      if (selectedPatientId === null || selectedPatientId === undefined)
        return { notes: [], page: 0, max_pages: 0 }
      const response = await getPatientNotes({
        user,
        patientId: selectedPatientId,
        page: pageParam.nextPage,
        search: pageParam.searchNote,
      })
      if (!response.ok) return { notes: [], page: 0, max_pages: 0 }
      return response
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return {
          nextPage: lastPage.page + 1,
          maxPages: lastPage.max_pages,
          searchNote: searchNoteDebounced,
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  const memoSearch = useMemo(() => {
    return (
      <Input
        value={searchNote}
        onChange={(e) => setSearchNote(e.target.value)}
        placeholder={
          <>
            <FaSearch className="mx-1 small" /> Buscar
          </>
        }
        groupStyle={{
          margin: '25px 0',
        }}
      />
    )
  }, [searchNote])

  const intObserver = useRef(null)
  const scrollEndRef = useCallback(
    (scrollEnd) => {
      if (isLoading) return
      if (intObserver.current) intObserver.current.disconnect()

      intObserver.current = new IntersectionObserver((scrollEnd) => {
        if (scrollEnd[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })

      if (scrollEnd) intObserver.current.observe(scrollEnd)
    },
    [isLoading, fetchNextPage, hasNextPage],
  )

  if (status === 'loading')
    return (
      <Row className="text-center justify-content-center mt-5">
        <Col className="mt-5" style={{ textAlign: 'right' }}>
          <div className="spinner-border text-primary" role="status" />
        </Col>
        <Col
          className="sr-only mt-5 pt-1"
          style={{ textAlign: 'left', fontSize: 22, marginTop: -5 }}
        >
          <i className="text-secondary">Cargando ...</i>
        </Col>
      </Row>
    )

  const noNotes =
    notesPages?.pages === undefined ||
    notesPages?.pages?.length === 0 ||
    notesPages?.pages?.reduce(
      (acc, value) => acc + (value?.notes?.length ?? 0),
      0,
    ) === 0

  const onDeleteAllNotes = async (patient) => {
    NotificationManager.warning(
      <Confirm
        message={
          <span>
            ¿Estas seguro de eliminar todas las notas de {patient.name}?
          </span>
        }
        onClick={async () => {
          const response = await deleteAllPatientNotes({
            user,
            patientId: patient.id,
          })
          if (response.ok) {
            NotificationManager.success('Notas eliminadas correctamente')
            queryClient.invalidateQueries('notes')

            if (oldPatients.filter((p) => p.id === patient.id).length > 0)
              queryClient.invalidateQueries('oldPatientsWithNotes')
          }
        }}
      />,
      null,
      100000,
    )
  }

  return (
    <>
      <Row>
        <Col lg={2} md={3} className="my-scroll full-screen d-none d-sm-block">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={
              <>
                <FaSearch className="mx-1 small" /> Buscar
              </>
            }
            groupStyle={{
              margin: '10px 0',
              marginBottom: 30,
            }}
          />
          {oldPatients?.map((patient) => (
            <PatientNotes
              key={patient.id}
              name={patient.name}
              lastSession={patient.lastSession}
              selected={patient.id === selectedPatientId}
              onClick={(e) => {
                setSelectedPatientId(patient.id)
                setSearchParams({ patientId: patient.id })
              }}
              onDeleteAllNotes={() => onDeleteAllNotes(patient)}
              warning={true}
            />
          ))}
          {patients.map((patient) => (
            <PatientNotes
              key={patient.id}
              name={patient.name}
              lastSession={patient.lastSession}
              selected={patient.id === selectedPatientId}
              onClick={(e) => {
                setSelectedPatientId(patient.id)
                setSearchParams({ patientId: patient.id })
              }}
              onDeleteAllNotes={() => onDeleteAllNotes(patient)}
            />
          ))}
        </Col>

        <Col lg={10} md={9} className="mb-3">
          {notesStatus === 'loading' ? (
            <Row className="text-center justify-content-center mt-5">
              <Col className="mt-5" style={{ textAlign: 'right' }}>
                <div className="spinner-border text-primary" role="status" />
              </Col>
              <Col
                className="sr-only mt-5 pt-1"
                style={{ textAlign: 'left', fontSize: 22, marginTop: -5 }}
              >
                <i className="text-secondary">Cargando ...</i>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row
            className="justify-content-end mx-3"
            style={{ marginBottom: -90 }}
          >
            <Col md={6} lg={3}>
              {memoSearch}
            </Col>
          </Row>
          {selectedPatientId !== null &&
          selectedPatientId !== undefined &&
          notesStatus === 'success' ? (
            <>
              <Row className="mt-5 mt-lg-3">
                <Col
                  className="mt-5 mt-lg-3"
                  lg={{ offset: 5, span: 2 }}
                  md={{ offset: 4, span: 4 }}
                  sm={{ offset: 2, span: 8 }}
                >
                  <Button
                    className="bp-btn mt-4"
                    onClick={async () => {
                      const defaultNoNotesContent =
                        '<h4>Apertura</h4><ul><li><strong>Edad</strong>: </li><li><strong>Situación familiar</strong>: </li><li><strong>Situación social</strong>: </li><li><strong>Problema principal</strong>: </li><li><strong>Problemas derivados</strong>: </li><li><strong>Cosas a trabajar</strong>: </li><li><strong>Temas pendientes</strong>: Amor, Asertividad, Autocontrol, Culpa, Duelo, EAD, Ego, Fuerza, Identidad, Manipulación, Percepción, Relajación</li></ul>'
                      await addOrUpdatePatientNote({
                        user,
                        patientId: selectedPatientId,
                        note: {
                          content: noNotes ? defaultNoNotesContent : '<p></p>',
                          creation_time: new Date(),
                        },
                      })
                      queryClient.invalidateQueries('notes')
                      queryClient.invalidateQueries('oldPatientsWithNotes')
                    }}
                  >
                    Añadir nota
                  </Button>
                </Col>
              </Row>
              {noNotes ? (
                <Row className="text-center justify-content-center mt-5 mb-4">
                  <Col
                    className="sr-only mt-3 pt-1"
                    style={{ textAlign: 'center', fontSize: 22, marginTop: -5 }}
                  >
                    <FaRegStickyNote
                      className="text-secondary mx-2"
                      size={40}
                    />
                    <i className="text-secondary">
                      No hay notas para este paciente con los filtros
                      seleccionados
                    </i>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {notesPages?.pages?.map(({ notes }) =>
                notes?.map((note) => (
                  <Note
                    key={note.creation_time}
                    isPinned={note.is_pinned}
                    data={note.content}
                    date={new Date(note.modification_time)}
                    patientId={selectedPatientId}
                    onSave={async (data) => {
                      await addOrUpdatePatientNote({
                        user,
                        patientId: selectedPatientId,
                        note: {
                          content: data,
                          creation_time: note.creation_time,
                        },
                      })
                      queryClient.invalidateQueries('notes')
                    }}
                    onDelete={async () => {
                      NotificationManager.warning(
                        <Confirm
                          message={
                            <span>
                              ¿Estas seguro de eliminar la nota de con fecha de{' '}
                              {dateToString(new Date(note.creation_time))}?
                            </span>
                          }
                          onClick={async () => {
                            const response = await deletePatientNote({
                              user,
                              patientId: selectedPatientId,
                              creationTime: note.creation_time,
                            })
                            if (response.ok) {
                              queryClient.invalidateQueries('notes')
                            }
                          }}
                        />,
                        null,
                        100000,
                      )
                    }}
                    onPin={async () => {
                      await swapPinNote({
                        user,
                        patientId: selectedPatientId,
                        creationTime: note.creation_time,
                      })
                      queryClient.invalidateQueries('notes')
                    }}
                  />
                )),
              )}
            </>
          ) : (
            <></>
          )}
          {(selectedPatientId === null || selectedPatientId === undefined) &&
          notesStatus === 'success' ? (
            <Row className="text-center justify-content-center mt-5">
              <Col
                className="sr-only mt-3 pt-1"
                style={{ textAlign: 'center', fontSize: 22, marginTop: -5 }}
              >
                <div className="my-5 my-lg-0">
                  <FaRegStickyNote className="text-secondary mx-2" size={40} />
                  <i className="text-secondary">
                    Selecciona un paciente para ver sus notas
                  </i>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div ref={scrollEndRef}></div>
        </Col>
      </Row>
    </>
  )
}
