import React, { useRef, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { FaRegStopCircle, FaUmbrellaBeach } from 'react-icons/fa'

import Input from '../material-forms/Input'
import Select from '../material-forms/Select'
import TextArea from '../material-forms/TextArea'

import { useAuth } from '../../contexts/AuthContext'

import Confirm from '../notification/Confirm'
import { dateToString } from '../../services/utils'
import { getPatientNames } from '../../services/patient'
import { addSession, addVacation } from '../../services/session'

function addHours(numOfHours, date = new Date()) {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000)
  return date
}

export default function AddSession({
  defaultDateValue = null,
  onSubmit = () => {},
  ...props
}) {
  const DEFAULT_PRICE = 35
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const dateRef = useRef()
  const durationRef = useRef()

  const [isPriceManuallyUpdated, setIsPriceManuallyUpdated] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState(30)
  const [selectedPatient, setSelectedPatient] = useState(null)

  const { data, status } = useQuery(
    'patientNames',
    async () => {
      const response = await getPatientNames({ user })
      return response.patients
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  )

  useEffect(() => {
    if (isPriceManuallyUpdated || selectedPatient === null) return
    setSelectedPrice(selectedPatient?.default_price ?? DEFAULT_PRICE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient])

  return (
    <>
      <Row className="mx-sm-5 mx-2 mt-2" {...props}>
        <Col lg={12}>
          <h5>Añadir nueva sesión</h5>
        </Col>
      </Row>
      <Form
        onSubmit={async (e) => {
          e.preventDefault()
          const sessionData = {
            patient_id: e.target.patient.value,
            date: e.target.date.value,
            concept: e.target.concept.value,
            duration: e.target.duration.value,
            cost: e.target.price.value,
            payment_method: e.target.paymentMethod.value,
            notes: e.target.notes.value,
          }

          // Ensure all required fields are filled
          for (const key in sessionData) {
            if (key === 'notes') continue
            if (
              sessionData[key] === '' ||
              sessionData[key] === null ||
              sessionData[key] === undefined
            ) {
              NotificationManager.error(
                'Rellena todos los campos requeridos para poder añadir una sesión',
              )
              return
            }
          }

          const response = await addSession({ user, sessionData })
          if (response.ok) {
            queryClient.invalidateQueries('nextSession')
            queryClient.invalidateQueries('nextSessions')
            queryClient.invalidateQueries('previousSessions')
            queryClient.invalidateQueries('calendar')
            NotificationManager.success('Sesión añadida')
            e.target.reset()
            onSubmit()
          } else {
            NotificationManager.error(
              'Ha ocurrido un error al añadir la sesión',
            )
          }
        }}
      >
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3 text-nowrap">
              Paciente <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Select
              name="patient"
              label="Paciente"
              disabled={status !== 'success'}
              onChange={(e) => {
                const patient = data?.filter((patient) => patient.id === parseInt(e.target.value))[0]
                setSelectedPatient(patient)
              }}
            >
              {status === 'success' &&
                data?.map((patient) => (
                  <option key={patient.id} value={patient.id}>
                    {patient.name + ' ' + patient.last_name}
                  </option>
                ))}
            </Select>
          </Col>
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3 text-nowrap">
              Fecha <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Form.Control
              ref={dateRef}
              name="date"
              type="datetime-local"
              className="bp-text-dark"
              defaultValue={
                defaultDateValue === null ? addHours(1) : defaultDateValue
              }
              style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}
            />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3 text-nowrap">
              Concepto <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Select name="concept" label="Concepto" defaultValue="Psicología">
              <option value="Psicología">Psicología</option>
              <option value="Coaching">Coaching</option>
              <option value="Evaluación">Evaluación</option>
              <option value="Cierre">Cierre</option>
              <option value="Otros">Otros</option>
            </Select>
          </Col>
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3 text-nowrap">
              Duración <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input
              inputRef={durationRef}
              name="duration"
              type="number"
              defaultValue={60}
              placeholder={'Minutos'}
            />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3 text-nowrap">
              Precio <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input
              name="price"
              type="number"
              value={selectedPrice}
              onChange={(e) => {
                setIsPriceManuallyUpdated(true)
                setSelectedPrice(e.target.value)
              }}
              placeholder={'Precio'}
            />
          </Col>
          <Col lg={{ offset: 1, span: 1 }} md={2} xs={4}>
            <div className="mt-3 text-nowrap">
              Pago <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Select
              name="paymentMethod"
              label="Método de pago"
              defaultValue="Bizum"
            >
              <option value="Tarjeta de Crédito">Tarjeta de Crédito</option>
              <option value="Tarjeta de Débito">Tarjeta de Débito</option>
              <option value="Bizum">Bizum</option>
              <option value="PayPal">PayPal</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Transferencia Bancaria">Transferencia Bancaria</option>
              <option value="Otro">Otro</option>
            </Select>
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 9 }}>
            <TextArea
              name="notes"
              placeholder="Notas rápidas sobre la sesión"
            />
          </Col>
        </Row>
        <Row className="my-4 mx-md-5 mx-2">
          <Col
            lg={{ offset: 5, span: 2 }}
            md={{ offset: 4, span: 4 }}
            sm={{ offset: 2, span: 8 }}
          >
            <Button type="submit" className="bp-btn">
              Añadir sesión
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mx-sm-5 mx-2 mt-2">
        <Col lg={12}>
          <h5>Acciones</h5>
        </Col>
      </Row>
      <Row className="mx-sm-5 mx-2">
        <Col lg={12}>
          <Button
            size="sm"
            className="my-2 mx-2 btn-secondary-action emphasize-on-hover"
            onClick={async () => {
              const date = dateRef.current.value.split('T')[0]
              const vacationData = {
                date,
                complete_day: true,
                duration: 60*24,
              }
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de querer marcar el día {dateToString(vacationData.date)}{' '}
                      como vacaciones?
                    </span>
                  }
                  onClick={async () => {
                    const response = await addVacation({
                      user,
                      vacationData,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('calendar')
                      NotificationManager.info('Confirmación de vacaciones creada')
                      onSubmit()
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
          >
            <FaUmbrellaBeach className="mb-1" />
          </Button>
          <i>Marcar como vacaciones</i>
        </Col>
        <Col lg={12}>
          <Button
            size="sm"
            className="my-2 mx-2 btn-modify emphasize-on-hover"
            onClick={async () => {
              const vacationData = {
                date: dateRef.current.value,
                complete_day: false,
                duration: durationRef.current.value,
              }
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de querer marcar el día/hora {dateToString(vacationData.date)}{' '}
                      como ocupado?
                    </span>
                  }
                  onClick={async () => {
                    const response = await addVacation({
                      user,
                      vacationData,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('calendar')
                      NotificationManager.info('Confirmación de vacaciones creada')
                      onSubmit()
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
          >
            <FaRegStopCircle className="mb-1" />
          </Button>
          <i>Marcar hora de la sesión como ocupada</i>
        </Col>
      </Row>
    </>
  )
}
