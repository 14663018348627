import React, { useEffect, useState, useMemo } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

import {
  FaCalendarTimes,
  FaChevronLeft,
  FaChevronRight,
  FaCircle,
  FaRegCircle,
  FaSearch,
} from 'react-icons/fa'

import Confirm from '../notification/Confirm'
import { dateToString } from '../../services/utils'
import Checkbox from '../../components/material-forms/Checkbox'
import Input from '../../components/material-forms/Input'
import { deleteVacation, getSessionsInDateRange } from '../../services/session'
import Modal from '../modal/Modal'
import Select from '../material-forms/Select'
import AddSession from '../add-session/AddSession'
import EditSession from '../add-session/EditSession'
import SessionDetails from '../add-session/SessionDetails'
import { useAuth } from '../../contexts/AuthContext'

function getDaysOfMonth(month, year) {
  // Calculate the number of days in the month and the day of the week the month begins
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  let firstDayOfWeek = new Date(year, month, 1).getDay()

  // Create an array to store the days of the month
  const daysOfMonth = []

  // Add the days of the month in order
  for (let i = 1; i <= daysInMonth; i++) {
    daysOfMonth.push(i)
  }

  // Add the missing days from the previous month
  let daysToAddFromPreviousMonth = (firstDayOfWeek - 1) % 7
  daysToAddFromPreviousMonth = daysToAddFromPreviousMonth < 0 ? 6 : daysToAddFromPreviousMonth
  const daysInPreviousMonth = new Date(year, month, 0).getDate()
  for (
    let i = daysInPreviousMonth;
    i > daysInPreviousMonth - daysToAddFromPreviousMonth;
    i--
  ) {
    daysOfMonth.unshift(i)
  }

  // If the length is not equal to 35 (5 weeks), add the missing days from the next month in order
  if (daysOfMonth.length !== 35 && daysOfMonth.length !== 42) {
    const roundTo = daysOfMonth.length > 35 ? 42 : 35
    const daysInNextMonth = roundTo - daysOfMonth.length
    for (let i = 1; i <= daysInNextMonth; i++) {
      daysOfMonth.push(i)
    }
  }

  // Return the days of the month in order
  return daysOfMonth
}

export default function CalendarTable() {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const now = new Date()
  const [isPrivateMode, setIsPrivateMode] = useState(false)
  const [year, setYear] = useState(now.getFullYear())
  const [month, setMonth] = useState(now.getMonth())
  const [newSession, setNewSession] = useState(null)
  const [sessionDetail, setSessionDetail] = useState(null)
  const [search, setSearch] = useState('')
  const [showPaid, setShowPaid] = useState(true)
  const [showPending, setShowPending] = useState(true)

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ]

  const daysOfMonth = getDaysOfMonth(month, year)

  const fromDate =
    daysOfMonth[0] !== 1
      ? new Date(year, month - 1, daysOfMonth[0] + 1)
      : new Date(year, month, 1)

  const toDate = new Date(fromDate)
  toDate.setDate(fromDate.getDate() + daysOfMonth.length)

  const { data: sessions, status } = useQuery(
    ['calendar', fromDate, toDate, search, showPaid, showPending],
    async () => {
      const sessions = await getSessionsInDateRange({
        user,
        fromDate: fromDate.toISOString().split('T')[0],
        toDate: toDate.toISOString().split('T')[0],
        search,
        showPaid,
        showPending,
      })
      const dateToSessions = {}
      sessions?.sessions?.forEach((session) => {
        const date = new Date(session.date.replace(' GMT', ''))
        const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
        if (dateToSessions[key]) {
          dateToSessions[key].push(session)
        } else {
          dateToSessions[key] = [session]
        }
      })
      sessions?.vacations?.forEach((vacation) => {
        const date = new Date(vacation.date.replace(' GMT', ''))
        const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
        vacation.vacation = true
        if (dateToSessions[key]) {
          dateToSessions[key].push(vacation)
        } else {
          dateToSessions[key] = [vacation]
        }
      })
      return dateToSessions
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  const memoSearch = useMemo(() => {
    return (
      <Input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={
          <>
            <FaSearch className="mx-1 small" /> Buscar
          </>
        }
        groupStyle={{
          margin: '25px 0',
        }}
      />
    )
  }, [search])

  const previousMonth = () => {
    setMonth((prevMonth) => {
      if (prevMonth === 0) {
        setYear((prevYear) => prevYear - 1)
        return 11
      } else {
        return prevMonth - 1
      }
    })
  }

  const nextMonth = () => {
    setMonth((prevMonth) => {
      if (prevMonth === 11) {
        setYear((prevYear) => prevYear + 1)
        return 0
      } else {
        return prevMonth + 1
      }
    })
  }

  useEffect(() => {
    const keyPress = (event) => {
      if (event.keyCode === 39) {
        // RIGHT
        nextMonth()
      } else if (event.keyCode === 37) {
        // LEFT
        previousMonth()
      }
    }
    window.addEventListener('keydown', keyPress)

    return () => {
      window.removeEventListener('keydown', keyPress)
    }
  }, [])

  const dateToHourMinute = (row_date) => {
    const date = new Date(row_date.replace(' GMT', ''))
    return ` ${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  }

  const getDate = (index) => {
    let dateMonth = month
    let dateYear = year
    if (index < 7 && daysOfMonth[index] > 7) {
      dateMonth = month - 1
      if (dateMonth < 0) {
        dateMonth = 11
        dateYear = year - 1
      }
    } else if (index > 20 && daysOfMonth[index] < 7) {
      dateMonth = month + 1
      if (dateMonth > 11) {
        dateMonth = 0
        dateYear = year + 1
      }
    }
    const date = new Date(dateYear, dateMonth, daysOfMonth[index])
    return date
  }

  const buildDateInfo = (index) => {
    const date = getDate(index)
    const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    if (!sessions || !sessions[key]) {
      return <></>
    }

    return (
      <div className="all-day-sessions">
        {sessions[key]?.map((session) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()

                if (session.vacation) {
                  NotificationManager.warning(
                    <Confirm
                      message={
                        <span>
                          ¿Estas seguro de querer borrar el día {dateToString(session.date)}{' '}
                          {session.complete_day ? "de vacaciones" : "como ocupado"}?
                        </span>
                      }
                      onClick={async () => {
                        const response = await deleteVacation({
                          user,
                          vacationId: session.vacation_id,
                        })
                        if (response.ok) {
                          queryClient.invalidateQueries('calendar')
                          NotificationManager.info('Eliminado correctamente')
                        }
                      }}
                    />,
                    null,
                    100000,
                  )
                } else {
                  setSessionDetail(session)
                }
              }}
              key={session.id}
              className={`calendar-session ${session.vacation ? "calendar-vacation" : ""}`}
            >
              {session.vacation ? (
                <FaCalendarTimes className="mr-2" />
              ) : (
                <></>
              )}
              {
                !session.vacation || !session.complete_day ? (
                  <strong>{dateToHourMinute(session.date)}</strong>
                ) : <></>
              }
              {
                session.vacation ? (
                  session.complete_day ? (
                    <i className="mx-2">Vacaciones</i>
                  ) : <i className="mx-2">Ocupado</i>
                ) : (
                <>
                  {session.paid ? (
                    <FaCircle className="mr-2 primary-color" size={8} />
                  ) : (
                    <FaRegCircle className="mr-2 primary-color" size={8} />
                  )}
                  {isPrivateMode ? '*'.repeat(Math.min(session.patient_name.length, 8)) : session.patient_name} {
                    isPrivateMode ? '*'.repeat(Math.min(session.patient_last_name.length, 8)) : session.patient_last_name
                  }
                </>
                )
              }
            </div>
          )
        })}
      </div>
    )
  }

  const validYears = []
  for (let i = 2019; i <= now.getFullYear() + 1; i++) {
    validYears.push(i)
  }

  const numSessions = Object.keys(sessions ?? []).reduce((acc, date) => {
    return acc + sessions[date].filter(
      (s) => !s.vacation
    ).length
  }, 0)
  const numBenefits = Object.keys(sessions ?? []).reduce((acc, date) => {
    return (
      acc +
      sessions[date].reduce((acc2, s) => (s.paid ? acc2 + s.cost : acc2), 0)
    )
  }, 0)
  const numPendingBenefits = Object.keys(sessions ?? []).reduce((acc, date) => {
    return (
      acc +
      sessions[date].reduce((acc2, s) => (!s.paid ? acc2 + s.cost : acc2), 0)
    )
  }, 0)

  return (
    <>
      <Modal
        show={sessionDetail !== null}
        onHide={() => {
          setSessionDetail(null)
        }}
      >
        {sessionDetail !== null ? (
          <>
            <EditSession
              session={sessionDetail}
              onSubmit={() => setSessionDetail(null)}
            />
            <SessionDetails
              session={sessionDetail}
              onSubmit={() => setSessionDetail(null)}
            />
          </>
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        show={newSession !== null}
        onHide={() => {
          setNewSession(null)
        }}
      >
        {newSession !== null ? (
          <AddSession
            defaultDateValue={newSession}
            onSubmit={() => {
              setNewSession(null)
            }}
          />
        ) : (
          <></>
        )}
      </Modal>
      <Row>
        <Col lg={2} sm={12} className="mt-4">
          <Row>
            <Col md={12}>
              <Button
                className="btn-no-bg mx-1"
                onClick={() => {
                  setMonth(now.getMonth())
                  setYear(now.getFullYear())
                }}
              >
                Hoy
              </Button>
              <Button
                className="btn-empty"
                onClick={() => {
                  previousMonth()
                }}
              >
                <FaChevronLeft style={{ marginTop: -5 }} />
              </Button>
              <Button
                className="btn-empty"
                onClick={() => {
                  nextMonth()
                }}
              >
                <FaChevronRight style={{ marginTop: -5 }} />
              </Button>

              <span className="mx-1 float-right h5">
                {months[month]} {year}
              </span>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <i>
                {numSessions} sesiones
                {numBenefits > 0 ? (
                  <>
                    <br />
                    <span>{isPrivateMode ? '****' : numBenefits}€ brutos ya pagados</span>
                  </>
                ) : (
                  <></>
                )}
                {numPendingBenefits > 0 ? (
                  <>
                    <br />
                    <span>{isPrivateMode ? '****' : numPendingBenefits}€ pendientes</span>
                  </>
                ) : (
                  <></>
                )}
              </i>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6} lg={12}>
              <Row className="mt-3">
                <Col md={12}>{memoSearch}</Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Select
                    value={month.toString()}
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                    name="month"
                    label="Mes"
                  >
                    <option value="0">Enero</option>
                    <option value="1">Febrero</option>
                    <option value="2">Marzo</option>
                    <option value="3">Abril</option>
                    <option value="4">Mayo</option>
                    <option value="5">Junio</option>
                    <option value="6">Julio</option>
                    <option value="7">Agosto</option>
                    <option value="8">Septiembre</option>
                    <option value="9">Octubre</option>
                    <option value="10">Noviembre</option>
                    <option value="11">Diciembre</option>
                  </Select>
                </Col>
                <Col md={6}>
                  <Select
                    value={year.toString()}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    name="year"
                    label="Año"
                  >
                    {validYears.map((y) => {
                      return (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      )
                    })}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col md={6} lg={12}>
              <Row className="mt-5">
                <Col md={12}>
                  <div className="mb-3">
                    <strong>Pagos</strong>
                  </div>
                  <Checkbox
                    onChange={() => setShowPending((prev) => !prev)}
                    label="Pendiente"
                    defaultChecked
                  />
                  <Checkbox
                    onChange={() => setShowPaid((prev) => !prev)}
                    label="Pagado"
                    defaultChecked
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6} lg={12}>
              <Row className="mt-5">
                <Col md={12}>
                  <div className="mb-3">
                    <strong>Modo privado</strong>
                  </div>
                  <Checkbox
                    onChange={() => setIsPrivateMode((prev) => !prev)}
                    label="Activo"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={10} sm={12} className="mb-1">
          <div className="text-center flex-grid mb-2">
            {Array(7)
              .fill(0)
              .map((_, i) => {
                return (
                  <div key={`header-${i}`} className="col-grid">
                    {days[(i + 1) % days.length]}
                  </div>
                )
              })}
          </div>
          <div
            className="text-center all-vertical"
            style={{ borderColor: 'rgb(218,220,224)' }}
          >
            {Array(daysOfMonth.length > 35 ? 6 : 5)
              .fill(0)
              .map((_, i) => {
                return (
                  <div key={`row-${i}`} className={`flex-grid partial-vertical ${daysOfMonth.length > 35 ? "small" : ""}`}>
                    {Array(7)
                      .fill(0)
                      .map((_, j) => {
                        return (
                          <div
                            key={`col-${j}`}
                            className={`col-grid calendar-cell my-scroll pt-1 ${
                              j === 0 ? 'left' : ''
                            } ${j === 6 ? 'right' : ''} ${
                              i === 0 ? 'top' : ''
                            } ${i === (daysOfMonth.length > 35 ? 5 : 4) ? 'bottom' : ''}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              const date = getDate(i * 7 + j)
                              const today = new Date(now)
                              today.setHours(0, 0, 0, 0)
                              if (date < today) return
                              const dateStr = `${date.getFullYear()}-${(
                                date.getMonth() + 1
                              )
                                .toString()
                                .padStart(2, '0')}-${date
                                .getDate()
                                .toString()
                                .padStart(2, '0')}T10:00`
                              setNewSession(dateStr)
                            }}
                          >
                            <span
                              className={`${
                                now.getDate() === daysOfMonth[i * 7 + j] &&
                                now.getMonth() === month &&
                                now.getFullYear() === year &&
                                !(i === 0 && daysOfMonth[i * 7 + j] > 7) &&
                                !(now.getDate() < 7 && i * 7 + j > 7 )
                                  ? 'today'
                                  : ''
                              }`}
                            >
                              {daysOfMonth[i * 7 + j]}
                            </span>
                            <br />
                            {status === 'success' ? (
                              buildDateInfo(i * 7 + j)
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      })}
                  </div>
                )
              })}
          </div>
        </Col>
      </Row>
    </>
  )
}
