import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { FaCloudDownloadAlt, FaTrash, FaMoneyBillWave } from 'react-icons/fa'

import Table from './Table'
import { useAuth } from '../../contexts/AuthContext'
import {
  addPayment,
  getInvoiceReport,
  getPayments,
  getRefundReport,
  deletePayment,
} from '../../services/payment'
import Confirm from '../notification/Confirm'

export function dateToAltString(row_date) {
  const date = new Date(row_date.replace(' GMT', ''))
  return (
    `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` +
    ` ${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
  )
}

export default function PaymentsTable({
  showPending,
  showPaid,
  startDate,
  endDate,
}) {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const dateToString = (row_date) => {
    const date = new Date(row_date.replace(' GMT', ''))
    return (
      `${date.getDate()} ${date.toLocaleString('default', {
        month: 'short',
      })} ${date.getFullYear()}` +
      ` ${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
    )
  }

  const PAYMENTS_COLUMNS = [
    {
      name: 'Fecha',
      selector: (row) => (
        <span title={dateToString(row.date)}>{dateToString(row.date)}</span>
      ),
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row) => (
        <span title={row.complete_name}>{row.complete_name}</span>
      ),
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => <span title={row.mail}>{row.mail}</span>,
      sortable: true,
    },
    {
      name: 'Teléfono',
      selector: (row) => (row?.phone ?? '').replace(' ', ''),
      sortable: true,
    },
    {
      name: 'Duración',
      selector: (row) => {
        const hours = Math.floor(row.duration / 60)
        const minutes = row.duration % 60
        if (hours === 0) return `${minutes}m`
        else if (minutes === 0) return `${hours}h`
        return `${hours}h ${minutes}m`
      },
      sortable: true,
      hide: 'lg',
    },
    {
      name: 'Precio',
      selector: (row) => `${row.cost}€`,
      sortable: true,
    },
    {
      name: 'Pago con',
      selector: (row) => row.payment_method,
      sortable: true,
      hide: 'lg',
    },
    {
      name: 'Pagado',
      selector: (row) => (row.paid ? 'Sí' : 'No'),
      sortable: true,
    },
    {
      name: 'Devuelto',
      selector: (row) => (row.refund_id !== null ? 'Sí' : 'No'),
      sortable: false,
    },
    {
      name: 'Acción',
      cell: (row) => (
        <div>
          <Button
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de haber recibido el pago del paciente{' '}
                      {row.complete_name} del día {dateToString(row.date)}?
                    </span>
                  }
                  onClick={async () => {
                    const response = await addPayment({
                      user,
                      sessionId: row.session_id,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('payments')
                      queryClient.invalidateQueries('paymentDetails')
                      queryClient.invalidateQueries('incomeByMonth')
                      queryClient.invalidateQueries('calendar')
                      NotificationManager.info('Confirmación de pago realizada')
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
            size="sm"
            className="my-1 btn-success emphasize-on-hover"
          >
            <FaMoneyBillWave className="mb-1" />
          </Button>
          <Button
            className="mx-1 my-1 btn-modify emphasize-on-hover"
            size="sm"
            disabled={!row?.paid}
            onClick={async () => {
              await getInvoiceReport({
                user,
                paymentId: row.id,
                saveAs: `F-${row.id}_${dateToAltString(row.pay_date)}.pdf`,
              })
              if (row.refund_id !== null) {
                await getRefundReport({
                  user,
                  paymentId: row.id,
                  refundId: row.refund_id,
                  saveAs: `A-${row.id}_${dateToAltString(row.pay_date)}.pdf`,
                })
              }
            }}
          >
            <FaCloudDownloadAlt className="mb-1" />
          </Button>
          <Button
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de eliminar el pago del paciente{' '}
                      {row.complete_name} del día {dateToString(row.date)}?
                    </span>
                  }
                  onClick={async () => {
                    console.log('payment', row)
                    const paymentId = row.id === null ? row.session_id : row.id
                    const response = await deletePayment({
                      user,
                      paymentId,
                      paid: row.paid,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('payments')
                      queryClient.invalidateQueries('paymentDetails')
                      queryClient.invalidateQueries('incomeByMonth')
                      NotificationManager.info('Pago eliminado correctamente')
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
            disabled={row.refund_id !== null}
            size="sm"
            className="my-1 btn-danger emphasize-on-hover"
          >
            <FaTrash className="mb-1" />
          </Button>
        </div>
      ),
    },
  ]

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState(undefined)
  const [asc, setAsc] = useState(true)

  const { data, status } = useQuery(
    [
      'payments',
      page,
      search,
      sortBy,
      pageSize,
      asc,
      showPending,
      showPaid,
      startDate,
      endDate,
    ],
    async () => {
      const params = {
        user,
        currentPage: page - 1,
        search,
        sortBy,
        pageSize,
        asc,
        showPending,
        showPaid,
        startDate,
        endDate,
      }

      const response = await getPayments(params)
      return {
        payments: response.payments,
        totalRows: response.total_rows,
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  return (
    <Table
      title={'Pagos / Facturas'}
      data={data?.payments}
      status={status}
      columns={PAYMENTS_COLUMNS}
      totalRows={data?.totalRows}
      onChangePage={(page) => {
        setPage(page)
      }}
      onChangeRowsPerPage={(newPerPage, page) => {
        setPageSize(newPerPage)
        setPage(page)
      }}
      onChangeSearch={(newSearch) => {
        setSearch(newSearch)
        setPage(1)
      }}
      onSort={(column, direction) => {
        switch (column.name) {
          case 'Fecha':
            setSortBy('date')
            break
          case 'Nombre':
            setSortBy('name_and_last_name')
            break
          case 'Email':
            setSortBy('mail')
            break
          case 'Teléfono':
            setSortBy('phone')
            break
          case 'Duración':
            setSortBy('duration')
            break
          case 'Precio':
            setSortBy('cost')
            break
          case 'Pago con':
            setSortBy('payment_method')
            break
          case 'Pagado':
            setSortBy('paid')
            break
          default:
            setSortBy(undefined)
        }
        setAsc(direction === 'asc')
        setPage(1)
      }}
    />
  )
}
