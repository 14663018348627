import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export default function Confirm({ message, onClick=() => console.log('Introduce your own confirm function')}) {
  return (
    <>
      <Row>
        <Col xs={12}>
          { message }
        </Col>
        <Col className="my-3" align="center" xs={12}>
          <Button
            onClick={onClick}
            className="original"
            variant="danger"
          >
            Confirmar
          </Button>
        </Col>
      </Row>
    </>
  )
}