import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AddPatient from '../../components/add-patient/AddPatient'
import PatientsRating from '../../components/charts/PatientsRating'
import PatientsTable from '../../components/table/PatientsTable'

export default function Patients() {
  return (
    <>
      {false && <PatientsRating />}
      <AddPatient />
      <Row>
        <Col md={12}>
          <div className="mx-5">
            <PatientsTable />
          </div>
        </Col>
      </Row>
    </>
  )
}
