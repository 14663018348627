import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useQuery, useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'
import {
  FaCopy,
  FaSignInAlt,
  FaTrash,
  FaUserEdit,
  FaWhatsapp,
} from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'

import Table from './Table'
import { getPendingSessions, getPreviousSessions } from '../../services/session'
import { useAuth } from '../../contexts/AuthContext'
import { deleteSession, handleAdminJoinSession } from '../../services/session'
import EditSession from '../add-session/EditSession'
import Confirm from '../notification/Confirm'
import Modal from '../modal/Modal'

export default function SessionsTable({ nextSessions = true }) {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const [sessionToEdit, setSessionToEdit] = useState(null)

  const dateToString = (row_date) => {
    const date = new Date(row_date.replace(' GMT', ''))
    return (
      `${date.getDate()} ${date.toLocaleString('default', {
        month: 'short',
      })} ${date.getFullYear()}` +
      ` ${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
    )
  }

  const patientToWhatsapp = (patient) => {
    const { patient_name, patient_phone } = patient
    if (!patient_phone) {
      return undefined
    }
    const text =
      `Buenas ${patient_name}, la siguiente sesión es el ${dateToString(
        patient.date,
      )}.\n` +
      `Para acceder usa el siguiente link: ${window.location.origin}/join/${patient.id}?checksum=${patient.checksum}`
    const phone =
      patient_phone?.length > 9
        ? patient_phone.replace('+', '')
        : `34${patient_phone}`
    return `https://wa.me/${phone}?text=${encodeURIComponent(text)}`
  }

  const NEXT_SESSION_COLUMNS = [
    {
      name: 'Fecha',
      selector: (row) => dateToString(row.date),
      sortable: true,
    },
    {
      name: 'Paciente',
      selector: (row) => `${row.patient_name} ${row.patient_last_name}`,
      sortable: true,
    },
    {
      name: 'Duración',
      selector: (row) => {
        const hours = Math.floor(row.duration / 60)
        const minutes = row.duration % 60
        if (hours === 0) return `${minutes}m`
        else if (minutes === 0) return `${hours}h`
        return `${hours}h ${minutes}m`
      },
      sortable: true,
    },
    {
      name: 'Notas rápidas',
      selector: (row) => row.notes,
      sortable: true,
    },
    {
      name: 'Acción',
      cell: (row) => (
        <div>
          <Button
            onClick={() => {
              handleAdminJoinSession(row.id)
            }}
            onMouseDown={(e) => {
              if (e.button === 1) {
                handleAdminJoinSession(row.id)
              }
            }}
            size="sm"
            className="mx-1 my-1 btn-action emphasize-on-hover"
          >
            <FaSignInAlt className="mx-1 mb-1" />
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/join/${row.id}?checksum=${row.checksum}`,
              )
              NotificationManager.info(
                'Se ha copiado el enlace al portapapeles',
              )
            }}
            size="sm"
            className="mx-1 my-1 emphasize-on-hover"
          >
            <FaCopy className="mx-1 mb-1" />
          </Button>
          <Button
            onClick={() => {
              setSessionToEdit(row)
            }}
            size="sm"
            className="mx-1 my-1 btn-modify emphasize-on-hover"
          >
            <FaUserEdit className="mx-1 mb-1" />
          </Button>
          <Button
            href={patientToWhatsapp(row)}
            target="_blank"
            size="sm"
            className="mx-1 my-1 emphasize-on-hover btn-success"
            onClick={() => {
              navigator.clipboard.writeText(
                `Buenas ${
                  row.patient_name
                }, la siguiente sesión es el ${dateToString(row.date)}.\n` +
                  `Para acceder usa el siguiente link: ${window.location.origin}/join/${row.id}?checksum=${row.checksum}`,
              )
              NotificationManager.info(
                'Se ha copiado el mensaje de Whatsapp al portapapeles',
              )
            }}
          >
            <FaWhatsapp className="mx-1 mb-1" />
          </Button>
          <Button
            onClick={() => {
              console.log('Click on email to', row)
            }}
            href={`mailto:${row.email}?subject=Hello from Abstract!&body=Just popped in to say hello`}
            size="sm"
            className="mx-1 my-1 btn-secondary-action emphasize-on-hover"
          >
            <AiOutlineMail className="mx-1 mb-1" />
          </Button>
          <Button
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de eliminar la sesión con {row.patient_name}
                      {row.patient_last_name} el día {dateToString(row.date)}?
                    </span>
                  }
                  onClick={async () => {
                    const response = await deleteSession({
                      user,
                      sessionId: row.id,
                    })
                    if (response.ok) {
                      queryClient.invalidateQueries('nextSession')
                      queryClient.invalidateQueries('nextSessions')
                      queryClient.invalidateQueries('previousSessions')
                      NotificationManager.info('Sesión eliminada')
                    }
                  }}
                />,
                null,
                100000,
              )
            }}
            size="sm"
            className="mx-1 mx-lg-3 my-1 btn-danger emphasize-on-hover"
          >
            <FaTrash className="mx-1 mb-1" />
          </Button>
        </div>
      ),
    },
  ]

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(nextSessions ? 20 : 10)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState(undefined)
  const [asc, setAsc] = useState(true)

  const { data, status } = useQuery(
    [
      nextSessions ? 'nextSessions' : 'previousSessions',
      page,
      search,
      sortBy,
      pageSize,
      asc,
    ],
    async () => {
      const params = {
        user,
        currentPage: page - 1,
        search: search,
        sortBy: sortBy,
        pageSize: pageSize,
        asc: asc,
      }

      const response = await (nextSessions
        ? getPendingSessions(params)
        : getPreviousSessions(params))
      return {
        sessions: response.sessions,
        totalRows: response.total_rows,
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  return (
    <>
      <Modal
        show={sessionToEdit !== null}
        onHide={() => {
          setSessionToEdit(null)
        }}
      >
        <EditSession
          session={sessionToEdit}
          onSubmit={() => setSessionToEdit(null)}
        />
      </Modal>
      <Table
        title={nextSessions ? 'Próximas sesiones' : 'Sesiones pasadas'}
        data={data?.sessions}
        status={status}
        columns={
          nextSessions
            ? NEXT_SESSION_COLUMNS
            : NEXT_SESSION_COLUMNS.filter((column) => column.name !== 'Acción')
        }
        totalRows={data?.totalRows}
        onChangePage={(page) => {
          setPage(page)
        }}
        onChangeRowsPerPage={(newPerPage, page) => {
          setPageSize(newPerPage)
          setPage(page)
        }}
        onChangeSearch={(newSearch) => {
          setSearch(newSearch)
          setPage(1)
        }}
        onSort={(column, direction) => {
          switch (column.name) {
            case 'Fecha':
              setSortBy('date')
              break
            case 'Paciente':
              setSortBy('name_and_last_name')
              break
            case 'Duración':
              setSortBy('duration')
              break
            case 'Concepto':
              setSortBy('concept')
              break
            case 'Notas rápidas':
              setSortBy('notes')
              break
            default:
              setSortBy(undefined)
          }
          setAsc(direction === 'asc')
          setPage(1)
        }}
        paginationPerPage={pageSize}
      />
    </>
  )
}
