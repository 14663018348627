import React from 'react'
import Button from 'react-bootstrap/Button'
import { useQuery } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { FaCopy, FaSignInAlt, FaTrash } from 'react-icons/fa'

import Table from './Table'
import { useAuth } from '../../contexts/AuthContext'
import Confirm from '../notification/Confirm'
import { getActiveConferences } from '../../services/server'
import {
  generateUserExpressSessionUrl,
  handleAdminExpressJoinSession,
  stopSession,
} from '../../services/session'

export default function ConferencesTable() {
  const { user } = useAuth()

  const CONFERENCES_COLUMNS = [
    {
      name: 'Nombre',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Número de usuarios',
      selector: (row) => row.users,
      sortable: true,
    },
    {
      name: 'Acción',
      cell: (row) => (
        <div>
          <Button
            onClick={() => {
              handleAdminExpressJoinSession(row.moderator_url)
            }}
            onMouseDown={(e) => {
              if (e.button === 1) {
                handleAdminExpressJoinSession(row.moderator_url)
              }
            }}
            size="sm"
            className="mx-1 my-1 btn-action emphasize-on-hover"
          >
            <FaSignInAlt className="mx-1 mb-1" />
          </Button>
          <Button
            size="sm"
            className="mx-1 my-1 emphasize-on-hover"
            onClick={() => {
              navigator.clipboard.writeText(
                generateUserExpressSessionUrl({
                  session: row.id,
                  sessionName: row.name,
                  checksum: row.user_checksum,
                }),
              )
              NotificationManager.info(
                'Se ha copiado el enlace al portapapeles',
              )
            }}
          >
            <FaCopy className="mx-1 mb-1" />
          </Button>
          <Button
            onClick={async () => {
              NotificationManager.warning(
                <Confirm
                  message={
                    <span>
                      ¿Estas seguro de querer cerrar la conferencia{' '}
                      <strong>{row.name}</strong>?
                    </span>
                  }
                  onClick={async () => {
                    await stopSession({
                      user,
                      sessionId: row.id,
                      moderatorPassowrd: row.moderator_password,
                    })
                  }}
                />,
                null,
                100000,
              )
            }}
            size="sm"
            className="mx-3 my-1 btn-danger emphasize-on-hover"
          >
            <FaTrash className="mx-1 mb-1" />
          </Button>
        </div>
      ),
    },
  ]

  const { data, status } = useQuery(
    'activeConferences',
    async () => {
      const response = await getActiveConferences({ user })
      return response.rooms
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
    },
  )

  return (
    <Table
      title={'Salas activas'}
      data={data}
      status={status}
      columns={CONFERENCES_COLUMNS}
    />
  )
}
