import React from 'react';

export default function Checkbox({ label="Checkbox", ...props }) {
  return (
    <div className="my-2">
      <label className="pure-material-checkbox checkbox">
        <input type="checkbox" {...props} />
        <span>{label}</span>
      </label>
    </div>
  );
}
  