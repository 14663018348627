import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

import Input from '../material-forms/Input'
import TextArea from '../material-forms/TextArea'

import { useAuth } from '../../contexts/AuthContext'
import { editPatient } from '../../services/patient'

export default function EditPatient({
  patient,
  onSubmit = () => {},
  ...props
}) {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return (
    <>
      <Row className="mx-sm-5 mx-2 mt-2" {...props}>
        <Col lg={12}>
          <h5>Editar paciente</h5>
        </Col>
      </Row>
      <Form
        onSubmit={async (e) => {
          e.preventDefault()
          const patientData = {
            patientId: patient.id,
            dni: e.target.dni.value,
            name: e.target.name.value,
            last_name: e.target.lastName.value,
            alias: e.target.alias.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            default_price: parseInt(e.target.defaultPrice.value),
            notes: e.target.subject.value,
          }

          // Ensure all required fields are filled
          for (const key in patientData) {
            if (key === 'alias' || key === 'email' || key === 'notes') continue
            if (
              patientData[key] === '' ||
              patientData[key] === null ||
              patientData[key] === undefined
            ) {
              NotificationManager.error(
                'Rellena todos los campos requeridos para poder editar los datos del paciente',
              )
              return
            }
          }

          const response = await editPatient({ user, patientData })
          if (response.ok) {
            queryClient.invalidateQueries('patients')
            queryClient.invalidateQueries('patientNames')
            queryClient.invalidateQueries('patientsCountSessions')
            NotificationManager.success(
              'Datos del paciente editados correctamente',
            )
            e.target.reset()
            onSubmit()
          }
        }}
      >
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              DNI <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="dni"
              type="text"
              placeholder={'DNI'}
              defaultValue={patient?.dni}
            />
          </Col>
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Nombre <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="name"
              type="text"
              placeholder={'Nombre'}
              defaultValue={patient?.name}
            />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Apellidos <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Input
              name="lastName"
              type="text"
              placeholder={'Apellidos'}
              defaultValue={patient?.last_name}
            />
          </Col>
          <Col md={2} xs={4} align="right">
            <div className="mt-3">Alias</div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="alias"
              type="text"
              placeholder={'Alias'}
              defaultValue={patient?.alias}
            />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col md={2} xs={4} align="right">
            <div className="mt-3">Email</div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="email"
              type="email"
              placeholder={'Email'}
              defaultValue={patient?.mail}
            />
          </Col>
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Teléfono <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="phone"
              type="text"
              placeholder={'Teléfono'}
              defaultValue={patient?.phone}
            />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col md={2} xs={4} align="right">
            <div className="mt-3">Precio</div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="defaultPrice"
              type="number"
              placeholder={'Precio por defecto'}
              defaultValue={patient?.default_price}
            />
          </Col>
        </Row>
        <Row className="mx-sm-5 mx-2 mt-4">
          <Col lg={{ offset: 1, span: 9 }}>
            <TextArea
              name="subject"
              placeholder="Notas rápidas sobre el paciente"
              defaultValue={patient?.notes}
            />
          </Col>
        </Row>
        <Row className="my-4 mx-md-5 mx-2">
          <Col md={{ offset: 4, span: 4 }} sm={{ offset: 2, span: 8 }}>
            <Button type="submit" className="bp-btn">
              Editar paciente
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
