import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useQueryClient } from 'react-query'
import { NotificationManager } from 'react-notifications'

import Input from '../material-forms/Input'
import Select from '../material-forms/Select'
import TextArea from '../material-forms/TextArea'

import { useAuth } from '../../contexts/AuthContext'

import { editSession } from '../../services/session'

export default function EditSession({
  session,
  onSubmit = () => {},
  ...props
}) {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const date = new Date((session?.date ?? '').replace(' GMT', '') ?? '')
  const dateStr = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)) // months from 1-12
    .slice(-2)}-${('0' + date.getDate()).slice(-2)}T${(
    '0' + date.getHours()
  ).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`

  return (
    <Container fluid>
      <Row className="mx-2 mt-2" {...props}>
        <Col lg={12}>
          <h5>Editar sesión</h5>
        </Col>
      </Row>
      <Form
        onSubmit={async (e) => {
          e.preventDefault()
          const sessionData = {
            sessionId: session?.id,
            date: e.target.date.value,
            concept: e.target.concept.value,
            duration: e.target.duration.value,
            cost: e.target.price.value,
            payment_method: e.target.paymentMethod.value,
            notes: e.target.notes.value,
          }

          // Ensure all required fields are filled
          for (const key in sessionData) {
            if (key === 'notes') continue
            if (
              sessionData[key] === '' ||
              sessionData[key] === null ||
              sessionData[key] === undefined
            ) {
              NotificationManager.error(
                'Rellena todos los campos requeridos para poder editar la sesión',
              )
              return
            }
          }

          const response = await editSession({ user, sessionData })
          if (response.ok) {
            queryClient.invalidateQueries('nextSession')
            queryClient.invalidateQueries('nextSessions')
            queryClient.invalidateQueries('previousSessions')
            queryClient.invalidateQueries('calendar')
            NotificationManager.success('Sesión editada correctamente')
            e.target.reset()
            onSubmit()
          }
        }}
      >
        <Row className="mt-4">
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Paciente <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Select label="Paciente" disabled defaultValue={0}>
              <option value={0}>
                {session?.patient_name} {session?.patient_last_name}
              </option>
            </Select>
          </Col>
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Fecha <span className="required">*</span>
            </div>
          </Col>
          <Col lg={3} md={10} xs={8}>
            <Form.Control
              name="date"
              type="datetime-local"
              className="bp-text-dark"
              defaultValue={dateStr}
              style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={2} xs={4}>
            <div className="mt-3" align="right">
              Concepto <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Select
              name="concept"
              label="Concepto"
              defaultValue={session?.concept}
            >
              <option value="Psicología">Psicología</option>
              <option value="Coaching">Coaching</option>
              <option value="Evaluación">Evaluación</option>
              <option value="Cierre">Cierre</option>
              <option value="Otros">Otros</option>
            </Select>
          </Col>
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Duración <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="duration"
              type="number"
              defaultValue={session?.duration}
              placeholder={'Minutos'}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Precio <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Input
              name="price"
              type="number"
              defaultValue={session?.cost}
              placeholder={'Precio'}
            />
          </Col>
          <Col md={2} xs={4} align="right">
            <div className="mt-3">
              Pago <span className="required">*</span>
            </div>
          </Col>
          <Col md={3} xs={8}>
            <Select
              name="paymentMethod"
              label="Método de pago"
              defaultValue={session?.payment_method}
            >
              <option value="Tarjeta de Crédito">Tarjeta de Crédito</option>
              <option value="Tarjeta de Débito">Tarjeta de Débito</option>
              <option value="Bizum">Bizum</option>
              <option value="PayPal">PayPal</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Transferencia Bancaria">Transferencia Bancaria</option>
              <option value="Otro">Otro</option>
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={{ offset: 1, span: 9 }}>
            <TextArea
              name="notes"
              placeholder="Notas rápidas sobre la sesión"
              defaultValue={session?.notes}
            />
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={{ offset: 4, span: 4 }} sm={{ offset: 2, span: 8 }}>
            <Button type="submit" className="bp-btn">
              Editar sesión
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
