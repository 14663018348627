import React from 'react'

export default function Input({ placeholder, groupStyle = {}, inputRef = undefined, ...props }) {
  if (inputRef) {
    props['ref'] = inputRef
  }
  return (
    <div className="group my-2 my-lg-0" style={groupStyle}>
      <input type="text" placeholder={placeholder} {...props} />
      <span className="bar"></span>
      <label>{placeholder}</label>
      <span className="highlight"></span>
    </div>
  )
}
