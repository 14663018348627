import request from './base';

export async function rebootConferenceSystem({ user }) {
  return await request({
    endpoint: '/admin/reboot_bbb',
    method: 'POST',
    user,
  })
}

export async function getActiveConferences({ user }) {
  return await request({
    endpoint: '/server/active-conferences',
    method: 'GET',
    user,
  })
}

export async function createExpressSession({ user, sessionName }) {
  return await request({
    endpoint: '/server/express-session',
    method: 'POST',
    user,
    queryParams: {
      session_name: sessionName,
    },
  })
}

export async function getServerStatus({ user }) {
  return await request({
    endpoint: '/server/status',
    method: 'GET',
    user,
  })
}

export async function powerOnServer({ user }) {
  return await request({
    endpoint: '/server/power-on',
    method: 'POST',
    user,
  })
}

export async function powerOffServer({ user }) {
  return await request({
    endpoint: '/server/power-off',
    method: 'POST',
    user,
  })
}
