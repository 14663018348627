import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default function ModalContainer({
  title,
  children,
  size = 'xl',
  onHide = () => {},
  closeText = undefined,
  centered = true,
  ...props
}) {
  return (
    <Modal {...props} onHide={onHide} size={size} centered={centered}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {closeText && (
        <Modal.Footer>
          <Button onClick={onHide}>{closeText}</Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
