import { useState, useEffect } from 'react'

export default function useStoreState({ key, ...initialValue }) {
  const retrieveFromLocalStore = () => {
    if (typeof window === 'undefined') {
      console.log("Can't access the local storage")
    } else
      try {
        const value = window.localStorage.getItem(key)
        return value ? JSON.parse(value) : initialValue
      } catch (error) {
        console.log(`Failed to retrieve value for ${key}`, error)
      }
    return initialValue
  }

  const [storedVal, setStoredVal] = useState(retrieveFromLocalStore)

  const setValue = (value) => {
    try {
      if (typeof window === 'undefined') {
        console.log("Can't access the local storage")
      } else {
        setStoredVal(value)
        window.localStorage.setItem(key, JSON.stringify(value))
      }
    } catch (error) {
      console.log(`Failed to store the key ${key}`, error)
    }
  }

  useEffect(
    () => {
      setStoredVal(retrieveFromLocalStore())
    },
    // eslint-disable-next-line
    [key],
  )

  return [storedVal, setValue]
}
