import React, { useRef, useEffect, useState } from 'react'
import autosize from 'autosize'

export default function Input({
  placeholder,
  groupStyle = {},
  defaultValue = undefined,
  ...props
}) {
  const areaRef = useRef()
  const [isEmpty, setIsEmpty] = useState(!defaultValue)

  useEffect(() => {
    autosize(areaRef.current)
  }, [])

  return (
    <div className="group" style={groupStyle}>
      <textarea
        onChange={() => setIsEmpty(!Boolean(areaRef.current.value))}
        onFocus={() => setIsEmpty(!Boolean(areaRef.current.value))}
        onBlur={() => setIsEmpty(!Boolean(areaRef.current.value))}
        ref={areaRef}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...props}
      />
      <span className="bar"></span>
      {isEmpty && <label>{placeholder}</label>}
      <span className="highlight"></span>
    </div>
  )
}
