import React from 'react'

import CalendarTable from '../../components/table/CalendarTable'

export default function Home() {
  return (
    <>
      <CalendarTable />
    </>
  )
}
