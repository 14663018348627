import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ClientSession from '../../components/client-session/ClientSession'
import ExpressSession from '../../components/client-session/ExpressSession'

export default function WaitSession() {
  // Get checksum id from react dom query paramater
  const { sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const checksum = searchParams.get('checksum')

  return (
    <Row className="mt-4 mx-3">
      <Col md={12} className="mb-2">
        <ClientSession sessionId={sessionId} checksum={checksum} />
      </Col>
    </Row>
  )
}

export function WaitExpressSession() {
  const { sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const checksum = searchParams.get('checksum')
  const sessionName = searchParams.get('sessionName')

  return (
    <Row className="mt-4 mx-3">
      <Col md={12} className="mb-2">
        <ExpressSession
          sessionId={sessionId}
          sessionName={sessionName}
          checksum={checksum}
        />
      </Col>
    </Row>
  )
}
