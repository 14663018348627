import React from 'react'
import { Link } from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'

import { useAuth } from '../../contexts/AuthContext'
import Input from '../material-forms/Input'
import { ThemeContext } from '../../contexts/ThemeContext'
import Switcher from '../material-forms/ThemeSwitcher'

export default function Login({ darkMode, setDarkMode }) {
  const { login } = useAuth()

  if (window.self !== window.top) {
    return (
      <Row>
        <Col md={12} align="center">
          Si ves esta página, el enlace de Webb App en el que has hecho clic
          está bajo un ataque de seguridad de Clickjacking.
          <h2>
            Por favor, informa al equipo con el de la aplicación desde la que
            has hecho clic en este enlace.
          </h2>
          <h2>
            Haz click{' '}
            <Link
              href={window.self.location.href}
              title="Web Application"
              target="blank"
            >
              aquí
            </Link>{' '}
            para acceder a la WebApp de forma segura.
          </h2>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row className="mt-5 justify-content-center">
        <Col lg={12} align="center">
          <Image
            src={
              darkMode
                ? '/BecomePositive_Negativo.png'
                : '/BecomePositive_Logo.png'
            }
            style={{ maxHeight: '100px' }}
          ></Image>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col
          lg={{ offset: 4, span: 4 }}
          md={{ offset: 3, span: 6 }}
          xs={{ offset: 1, span: 10 }}
          className="mt-4"
        >
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              login(event.target.username.value, event.target.password.value)
            }}
          >
            <Input name="username" type="text" placeholder="Username" />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              className="mt-4"
            />
            <Col className="mt-5 mx-4">
              <Button type="submit" className="bp-btn">
                Iniciar sesión
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <InputGroup style={{ justifyContent: 'center' }}>
            <ThemeContext.Consumer>
              {({ changeTheme }) => (
                <Switcher
                  darkMode={darkMode}
                  setDarkMode={setDarkMode}
                  changeTheme={changeTheme}
                />
              )}
            </ThemeContext.Consumer>
            <small className="mt-2 mx-2">
              Cambiar a modo {darkMode ? 'claro' : 'oscuro'}
            </small>
          </InputGroup>
        </Col>
      </Row>
    </>
  )
}
