import React from 'react'
import Button from 'react-bootstrap/Button'
import { useQuery } from 'react-query'
import { FaCloudDownloadAlt } from 'react-icons/fa'

import Table from './Table'
import { useAuth } from '../../contexts/AuthContext'
import { getReports, getReportZip } from '../../services/payment'

export default function ReportsTable() {
  const { user } = useAuth()

  const REPORTS_COLUMNS = [
    {
      name: 'Año',
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: 'Trimestre 1',
      selector: (row) =>
        row.trimester1 && (
          <Button
            className="mx-1 my-1 btn-modify emphasize-on-hover"
            size="sm"
            onClick={async () =>
              await getReportZip({
                user,
                year: row.year,
                trimester: 1,
              })
            }
          >
            <FaCloudDownloadAlt className="mb-1" />
          </Button>
        ),
      sortable: false,
    },
    {
      name: 'Trimestre 2',
      selector: (row) =>
        row.trimester2 && (
          <Button
            className="mx-1 my-1 btn-modify emphasize-on-hover"
            size="sm"
            onClick={async () =>
              await getReportZip({
                user,
                year: row.year,
                trimester: 2,
              })
            }
          >
            <FaCloudDownloadAlt className="mb-1" />
          </Button>
        ),
      sortable: false,
    },
    {
      name: 'Trimestre 3',
      selector: (row) =>
        row.trimester3 && (
          <Button
            className="mx-1 my-1 btn-modify emphasize-on-hover"
            size="sm"
            onClick={async () =>
              await getReportZip({
                user,
                year: row.year,
                trimester: 3,
              })
            }
          >
            <FaCloudDownloadAlt className="mb-1" />
          </Button>
        ),
      sortable: false,
    },
    {
      name: 'Trimestre 4',
      selector: (row) =>
        row.trimester4 && (
          <Button
            className="mx-1 my-1 btn-modify emphasize-on-hover"
            size="sm"
            onClick={async () =>
              await getReportZip({
                user,
                year: row.year,
                trimester: 4,
              })
            }
          >
            <FaCloudDownloadAlt className="mb-1" />
          </Button>
        ),
      sortable: false,
    },
    {
      name: 'Completo',
      selector: (row) => (
        <Button
          className="mx-1 my-1 btn-modify emphasize-on-hover"
          size="sm"
          onClick={async () =>
            await getReportZip({
              user,
              year: row.year,
            })
          }
        >
          <FaCloudDownloadAlt className="mb-1" />
        </Button>
      ),
      sortable: false,
    },
  ]

  const { data, status } = useQuery(
    'reports',
    async () => {
      const response = await getReports({ user })
      if (response.ok) {
        const { ok, ...data } = response
        const finalData = Object.keys(data).map((key) => {
          const value = new Set(data[key])
          return {
            year: key,
            trimester1: value.has('1'),
            trimester2: value.has('2'),
            trimester3: value.has('3'),
            trimester4: value.has('4'),
          }
        })
        return finalData
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  return (
    <Table
      title={'Descargar reportes'}
      data={data}
      status={status}
      columns={REPORTS_COLUMNS}
    />
  )
}
