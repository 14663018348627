import request from './base';

export async function getPayments({ user, pageSize=10, currentPage=0, sortBy=undefined, asc=true, search='', showPending=true, showPaid=false, startDate=null, endDate=null }) {
  return await request({
    endpoint: '/payment/',
    method: 'GET',
    user,
    queryParams: {
      page_size: pageSize,
      current_page: currentPage,
      sort_by: sortBy,
      asc: asc ? 'asc' : 'desc',
      search,
      show_pending: showPending,
      show_paid: showPaid,
      from_date: startDate,
      to_date: endDate,
    }
  })
}

export async function deletePayment({ user, paymentId, paid=0 }) {
  return await request({
    endpoint: `/payment/${paymentId}`,
    method: 'DELETE',
    user,
    queryParams: {
      paid
    }
  })
}

export async function addPayment({ user, sessionId }) {
  return await request({
    endpoint: `/payment/${sessionId}`,
    method: 'POST',
    user,
  })
}

export async function getInvoiceReport({ user, paymentId, saveAs }) {
  return await request({
    endpoint: `/payment/invoice-report/${paymentId}`,
    method: 'GET',
    user,
    isDownload: true,
    saveAs,
  })
}

export async function getRefundReport({ user, paymentId, refundId, saveAs }) {
  return await request({
    endpoint: `/payment/refund-report/${paymentId}`,
    method: 'GET',
    user,
    queryParams: {
      refund: refundId,
    },
    isDownload: true,
    saveAs,
  })
}

export async function getReports({ user }) {
  return await request({
    endpoint: '/payment/reports',
    method: 'GET',
    user,
  })
}

export async function getReportZip({ user, year, trimester=null }) {
  const saveAs = trimester === null ? `Year-${year}.zip` : `Year-${year}-Trimester-${trimester}.zip`
  return await request({
    endpoint: `/payment/report-zip/${year}`,
    method: 'GET',
    user,
    queryParams: {
      trimester,
    },
    isDownload: true,
    saveAs,
  })
}

export async function getIncomeByMonth({ user }) {
  return await request({
    endpoint: '/payment/income-by-month',
    method: 'GET',
    user,
  })
}
