import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReactHtmlParser from 'html-react-parser'
import { FaTrash } from 'react-icons/fa'
import { MdEdit, MdSave } from 'react-icons/md'
import { BsPinAngleFill, BsPinAngle } from 'react-icons/bs'
import { dateToString } from '../../services/utils'

import './Note.css'

export default function Note({
  data = '<p></p>',
  date = '',
  isPinned = false,
  onReady = (editor) => {},
  onChange = (event, editor) => {
    data = editor.getData()
  },
  onBlur = (event, editor) => {},
  onFocus = (event, editor) => {},
  onSave = (data) => {
    console.log('Saved with', data)
  },
  onDelete = () => {
    console.log('Deleted')
  },
  onPin = () => {
    console.log('Swap pin')
  },
}) {
  const [editing, setEditing] = useState(false)
  const [newData, setNewData] = useState(data)

  return (
    <Row className="mt-4 mx-3">
      <Col md={6} className="mb-2">
        {editing ? (
          <>
            <Button
              size="sm"
              className="mx-2 my-1 btn-success emphasize-on-hover"
              onClick={() => {
                setEditing(!editing)
                if (data === newData) return
                onSave(newData)
              }}
            >
              <MdSave size={18} /> Guardar
            </Button>
          </>
        ) : (
          <>
            <Button
              size="sm"
              className="mx-2 my-1 btn-modify emphasize-on-hover"
              onClick={() => setEditing(!editing)}
            >
              <MdEdit size={18} /> Editar
            </Button>
          </>
        )}
        <Button
          size="sm"
          className={`mx-2 my-1 btn-action ${
            isPinned ? '' : 'emphasize-on-hover'
          }`}
          onClick={() => {
            onPin()
          }}
        >
          {isPinned ? <BsPinAngleFill size={18} /> : <BsPinAngle size={18} />}
        </Button>
      </Col>
      <Col md={6} align="right">
        <span className="small mx-3">{dateToString(date)}</span>
        <Button
          size="sm"
          className="my-1 btn-danger emphasize-on-hover"
          onClick={onDelete}
        >
          <FaTrash />
        </Button>
      </Col>
      <Col md={12}>
        {editing ? (
          <CKEditor
            editor={ClassicEditor}
            disabled={false}
            data={newData}
            config={{
              toolbar: {
                items: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  '|',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'blockQuote',
                  '|',
                  'insertTable',
                  '|',
                  'undo',
                  'redo',
                ],
              },
              table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
              },
              language: 'es',
            }}
            onReady={onReady}
            onChange={onChange}
            onBlur={(event, editor) => {
              setNewData(editor.getData())
              return onBlur(event, editor)
            }}
            onFocus={onFocus}
          />
        ) : (
          <div className="mx-2 mt-1 w-100 note-dark-border rounded pt-3 px-3">
            {ReactHtmlParser(newData)}
          </div>
        )}
      </Col>
    </Row>
  )
}
