import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useQuery } from 'react-query'

import Input from '../material-forms/Input'
import { verifyChecksum } from '../../services/session'
import { handleExpressJoinSession } from '../../services/session'

export default function ExpressSession({ sessionId, sessionName, checksum }) {
  const { data: valid, status } = useQuery(
    ['session', sessionId, sessionName, checksum],
    async () =>
      await verifyChecksum({
        session_id: sessionId,
        session_name: sessionName,
        checksum,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  )

  const redirectToSession = () => {}

  return (
    <Container>
      <Row className="justify-content-md-center mx-3">
        <Col
          xl={4}
          lg={6}
          className="bp-bg-light bp-text-dark my-3"
          style={{ borderRadius: '15px' }}
        >
          {status === 'loading' ? (
            <Row align="center" className="mx-3 my-3">
              <Row className="text-center justify-content-center">
                <Col
                  className="my-4"
                  style={{ textAlign: 'right', marginLeft: -50 }}
                >
                  <div className="spinner-border text-primary" role="status" />
                </Col>
                <Col
                  className="sr-only my-4 pt-1"
                  style={{ textAlign: 'left', fontSize: 22, marginTop: -5 }}
                >
                  <i className="text-secondary">Cargando ...</i>
                </Col>
              </Row>
            </Row>
          ) : valid ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const form = e.currentTarget
                const name = form.elements.name.value
                handleExpressJoinSession({
                  sessionId,
                  sessionName,
                  name,
                  checksum,
                }).then(redirectToSession)
              }}
            >
              <Row align="center" className="mx-3 my-3">
                <h4>Accede a tu sesión exprés</h4>
                <Col md={12} align="right">
                  <div className="mt-3 mb-4">
                    Introduce tu nombre para acceder a la sesión
                  </div>
                </Col>
                <Col md={12}>
                  <Input
                    name="name"
                    type="text"
                    placeholder={'Nombre'}
                    defaultValue=""
                  />
                </Col>
              </Row>
              <Row align="center" className="my-4 mx-md-5">
                <Col md={12}>
                  <Button className={'bp-btn'} type="submit">
                    Entrar
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Row align="center" className="mx-3 my-3">
              <h4>Sesión no encontrada</h4>
              <p>La sesión no existe o ya ha finalizado</p>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="justify-content-md-center mx-3">
        <Col xl={4} lg={6} className="my-3 text-center">
          <p>
            <span className="text-secondary">Gracias por confiar en </span>
            <strong>Become Positive</strong>
          </p>
        </Col>
      </Row>
    </Container>
  )
}
