import { FiSun, FiMoon } from 'react-icons/fi'

import { themes } from '../../contexts/ThemeContext'

export default function Switcher({ darkMode, setDarkMode, changeTheme }) {
  return (
    <>
      <FiSun
        size={12}
        className="cursor-pointer"
        style={{
          position: 'absolute',
          right: 19,
          top: 12,
          zIndex: '1',
          color: 'white',
        }}
        onClick={(e) => {
          setDarkMode(!darkMode)
          changeTheme(darkMode ? themes.light : themes.dark)
        }}
      />
      <label className="switch mt-2">
        <input
          type="checkbox"
          onClick={(e) => {
            setDarkMode(!darkMode)
            changeTheme(darkMode ? themes.light : themes.dark)
          }}
          checked={darkMode}
          onChange={() => {}}
        />
        <span className="slider round"></span>
      </label>
      <FiMoon
        size={12}
        className="cursor-pointer"
        style={{
          position: 'absolute',
          left: 17,
          top: 12,
          zIndex: '1',
          color: 'white',
        }}
        onClick={(e) => {
          setDarkMode(!darkMode)
          changeTheme(darkMode ? themes.light : themes.dark)
        }}
      />
    </>
  )
}
