import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import Checkbox from '../../components/material-forms/Checkbox'
import MonthlyIncome from '../../components/charts/MonthlyIncome'
import PaymentsTable from '../../components/table/PaymentsTable'
import ReportsTable from '../../components/table/ReportsTable'

export default function Payments({
  darkMode=true,
}) {
  const [showPending, setShowPending] = useState(true)
  const [showPaid, setShowPaid] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  return (
    <>
      <MonthlyIncome darkMode={darkMode} />
      <Row>
        <Col lg={3} className="mt-lg-4">
          <Row className="mx-5 mt-lg-3">
            <Col align="center" xs={12}>
              <h5>Filtros</h5>
              <hr />
            </Col>
            <Col xs={12}>
              <div className="mb-3">
                <strong>Pagos</strong>
              </div>
              <Checkbox
                onChange={() => setShowPending((prev) => !prev)}
                label="Pendiente"
                defaultChecked
              />
              <Checkbox
                onChange={() => setShowPaid((prev) => !prev)}
                label="Pagado"
              />
              <hr />
            </Col>
            <Col xs={12}>
              <div className="mb-3">
                <strong>Rango de fechas</strong>
              </div>
              <Row>
                <Col xs={4}>
                  <div className="mt-3">
                    <span>Desde</span>
                  </div>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    type="datetime-local"
                    className="bp-text-dark"
                    style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div className="mt-3">
                    <span>Hasta</span>
                  </div>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    type="datetime-local"
                    className="bp-text-dark"
                    style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0)' }}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={9}>
          <div className="mx-3">
            <PaymentsTable
              showPending={showPending}
              showPaid={showPaid}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col md={12}>
          <div className="mx-5">
            <ReportsTable />
          </div>
        </Col>
      </Row>
    </>
  )
}
