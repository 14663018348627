import React, { useRef, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'

import { FaExclamationTriangle, FaTrash } from 'react-icons/fa'
import { BiDotsVerticalRounded } from 'react-icons/bi'

import './PatientNotes.css'

export default function PatientNotes({
  name,
  numSessions,
  lastSession,
  selected = false,
  warning = false,
  onDeleteAllNotes = () => {},
  ...props
}) {
  const [show, setShow] = useState(false)
  const ref = useRef(null)
  return (
    <Row
      className={`my-3 py-2 px-2 mx-1 patient-note ${
        selected ? 'selected' : ''
      }`}
      {...props}
    >
      <Col md={12}>
        <span style={{ float: 'right' }}>
          <Button
            ref={ref}
            size="sm"
            className="btn-dark emphasize-on-hover px-1"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShow(!show)
            }}
            onBlur={() => setShow(false)}
          >
            <BiDotsVerticalRounded size={22} />
          </Button>
          <Overlay target={ref.current} show={show} placement="right">
            {({
              placement: _placement,
              arrowProps: _arrowProps,
              show: _show,
              popper: _popper,
              hasDoneInitialMeasure: _hasDoneInitialMeasure,
              ...props
            }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  borderRadius: 3,
                  backgroundColor: 'rgba(33, 37, 41, 0.85)',
                  color: 'white',
                  ...props.style,
                }}
              >
                <Row className="mx-1 my-2">
                  <Col>
                    {warning ? (
                      <>
                        <span className="small text-warning">
                          Este paciente lleva más de 1 año sin sesiones.
                          <br />
                          Legalmente deberías borrar las notas.
                        </span>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    <Button
                      size="sm"
                      className="btn-danger emphasize-on-hover my-2 mx-1"
                      onClick={onDeleteAllNotes}
                    >
                      <FaTrash />
                    </Button>
                    <span className="small">Eliminar todas las notas</span>
                  </Col>
                </Row>
              </div>
            )}
          </Overlay>
        </span>
        {warning ? (
          <span className="text-danger small">
            <FaExclamationTriangle
              style={{ marginTop: -5, marginRight: 5, marginLeft: -5 }}
              size={14}
            />
          </span>
        ) : (
          <></>
        )}
        <strong>{name}</strong>
        <br />
        <span className="text-secondary small">
          Última sesión {lastSession}
        </span>
      </Col>
    </Row>
  )
}
