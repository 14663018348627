import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'
import { useNavigate, useLocation } from 'react-router-dom'
import { GoSignOut } from 'react-icons/go'

import { useAuth } from '../../contexts/AuthContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import Switcher from '../material-forms/ThemeSwitcher'

export default function BPNavbar({ darkMode, setDarkMode }) {
  const { logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Navbar
      expand="md"
      className="bp-darker-bg-light fixed-top"
      collapseOnSelect
    >
      <Container fluid className="mx-5">
        <Navbar.Brand
          className="bp-text-light cursor-pointer"
          onClick={() => navigate(`/`)}
        >
          <Image
            src={
              darkMode
                ? '/BecomePositive_Negativo.png'
                : '/BecomePositive_Logo.png'
            }
            style={{ maxHeight: '30px' }}
          ></Image>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="bg-light opacity-25"
          style={{ borderWidth: '0px' }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              className={`bp-text-dark mx-md-2 ${
                location.pathname === '/' ? 'selected' : ''
              }`}
              onClick={() => navigate(`/`)}
            >
              Calendario
            </Nav.Link>
            <Nav.Link
              className={`bp-text-dark mx-md-2 ${
                location.pathname === '/sessions' ? 'selected' : ''
              }`}
              onClick={() => navigate(`/sessions`)}
            >
              Sesiones
            </Nav.Link>
            <Nav.Link
              className={`bp-text-dark mx-md-2 ${
                location.pathname === '/patients' ? 'selected' : ''
              }`}
              onClick={() => navigate(`/patients`)}
            >
              Pacientes
            </Nav.Link>
            <Nav.Link
              className={`bp-text-dark mx-md-2 ${
                location.pathname === '/payments' ? 'selected' : ''
              }`}
              onClick={() => navigate(`/payments`)}
            >
              Pagos
            </Nav.Link>
            <Nav.Link
              className={`bp-text-dark mx-md-2 ${
                location.pathname === '/notes' ? 'selected' : ''
              }`}
              onClick={() => navigate(`/notes`)}
            >
              Notas
            </Nav.Link>
            <Nav.Link
              className={`bp-text-dark mx-md-2 ${
                location.pathname === '/server' ? 'selected' : ''
              }`}
              onClick={() => navigate(`/server`)}
            >
              Servidor
            </Nav.Link>
          </Nav>
          <Navbar.Brand>
            <Row>
              <Col md={12}>
                <InputGroup>
                  <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                      <Switcher
                        darkMode={darkMode}
                        setDarkMode={setDarkMode}
                        changeTheme={changeTheme}
                      />
                    )}
                  </ThemeContext.Consumer>
                </InputGroup>
              </Col>
            </Row>
          </Navbar.Brand>
          <Nav.Link className={`bp-text-dark mx-md-2`} onClick={() => logout()}>
            Cerrar sesión <GoSignOut />
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export function PublicNavbar({ darkMode, setDarkMode }) {
  return (
    <Navbar bg="dark" expand="lg" className="fixed-top">
      <Container fluid className="mx-5">
        <Navbar.Brand className="bp-text-light">
          <Image
            src={
              darkMode
                ? '/BecomePositive_Negativo.png'
                : '/BecomePositive_Negativo.png'
            }
            style={{ maxHeight: '30px' }}
          ></Image>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="bg-light opacity-25"
          style={{ borderWidth: '0px' }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className={`text-light mx-md-2 selected`}>
              Unirse a la sesión
            </Nav.Link>
            <Nav.Link
              className={`text-light mx-md-2`}
              onClick={() =>
                window.open('https://www.becomepositive.es/', '_blank')
              }
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (e.button === 1) {
                  window.open('https://www.becomepositive.es/', '_blank')
                }
              }}
            >
              Visitar web
            </Nav.Link>
          </Nav>
          <Navbar.Brand>
            <Row>
              <Col md={12}>
                <InputGroup>
                  <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                      <Switcher
                        darkMode={darkMode}
                        setDarkMode={setDarkMode}
                        changeTheme={changeTheme}
                      />
                    )}
                  </ThemeContext.Consumer>
                </InputGroup>
              </Col>
            </Row>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
