// Author https://github.com/yegor-sytnyk/movies-list

const date = new Date();
const dummyDate = (
  `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}` +
  ` ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
)

// eslint-disable-next-line 
export default [
  {
    date: dummyDate,
    name: 'Fernando Pérez Gutiérrez',
    duration: '1h',
    concept: 'Psicología',
    notes: 'Demasiado hermoso',
  },
];
