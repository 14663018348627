import { createContext, useContext, useEffect, useState } from 'react'
import useStoreState from '../hooks/UseStoreState'
import { login as loginService } from '../services/base'

const AuthContext = createContext({
  isLogged: null,
  login: () => {},
  logout: () => {},
})

export function AuthProvider({ children }) {
  const [isLogged, setIsLogged] = useState(false)
  const [user, setUser] = useStoreState({ username: null, password: null })

  const login = (username, password) => {
    setUser({ username, password })
  }

  const logout = () => {
    setUser({ username: null, password: null })
  }

  useEffect(() => {
    if (user.username && user.password) {
      loginService({ username: user.username, password: user.password }).then(
        (response) => {
          if (response) {
            setIsLogged(true)
          } else {
            setIsLogged(false)
          }
        },
      )
    } else {
      setIsLogged(false)
    }
  }, [user])

  return (
    <AuthContext.Provider value={{ isLogged, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
