import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import NextSession from '../../components/next-session/NextSession'
import AddSession from '../../components/add-session/AddSession'
import SessionsTable from '../../components/table/SessionsTable'

export default function Sessions() {
  return (
    <>
      <NextSession />
      <AddSession />
      <Row>
        <Col md={12}>
          <div className="mx-5">
            <SessionsTable nextSessions={true} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="mx-5">
            <SessionsTable nextSessions={false} />
          </div>
        </Col>
      </Row>
    </>
  );
}
