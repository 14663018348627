import * as base64js from 'base64-js';

export const API_URL = (process.env.REACT_APP_MODE || process.env.NODE_ENV) === `development` ? 'http://localhost:8080' : 'https://manager.becomepositive.es'

export function encodeQueryData(data) {
  const ret = [];
  for (let d in data) {
    if (data[d] === undefined || data[d] === null)
      continue
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
}

export default async function request({
  endpoint,
  method = 'GET',
  body,
  headers = {},
  user = {username: null, password: null},
  queryParams = {},
  isDownload = false,
  saveAs = null,
}) {
  try {
    if (user.username && user.password) {
      const encoder = new TextEncoder()
      const auth = `${user.username}:${user.password}`
      const base64Auth = base64js.fromByteArray(encoder.encode(auth))
      headers['Authorization'] = `Basic ${base64Auth}`
    }

    const query = encodeQueryData(queryParams)
    const response = await fetch(
      `${API_URL}${endpoint}${query ? `?${query}` : ''}`,
      {
        method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        },
      }
    )

    if (response.status === 401) {
      console.error('Unauthorized')
      return
    }

    if (!response.ok) {
      console.error('Error calling API', response)
      return
    }

    if (isDownload) {
      const download = await response.blob()
      const url = window.URL.createObjectURL(download)
      const anchor = document.createElement("a")
      anchor.href = url
      anchor.download = saveAs
      anchor.click()

      // CLEAN UP
      window.URL.revokeObjectURL(url)
      try {
        document.removeChild(anchor)
      } catch (error) {}
      return
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling API', error)
  }
}

export async function login({ username, password }) {
  const encoder = new TextEncoder();
  const auth = `${username}:${password}`;
  const base64Auth = base64js.fromByteArray(encoder.encode(auth));

  return await request({
    endpoint: '/login',
    method: 'GET',
    headers: {
      Authorization: `Basic ${base64Auth}`,
    },
  })
}
