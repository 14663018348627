import React, { useEffect, useRef, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useQuery } from 'react-query'
import { ColorType, createChart } from 'lightweight-charts'
import { getIncomeByMonth } from '../../services/payment'
import { useAuth } from '../../contexts/AuthContext'

const formatDate = (timeString) => {
  const date = new Date(timeString)
  const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ]
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  return { formattedDate: `${month} ${year}`, year }
}

export default function MonthlyIncome({ darkMode = true }) {
  const chartContainerRef = useRef()
  const tooltipRef = useRef()
  const { user } = useAuth()
  const [chart, setChart] = useState(null)
  const [areaSeriesPaid, setAreaSeriesPaid] = useState(null)
  const [areaSeriesPending, setAreaSeriesPending] = useState(null)

  // Usar useQuery para obtener los datos desde la API
  const { data: monthlyData, isLoading, error } = useQuery(
    'incomeByMonth',
    async () => {
      const response = await getIncomeByMonth({ user })
      return response.income
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  )

  const calculateTotalsByYear = (year) => {
    const totals = monthlyData.filter(d => new Date(d.time).getFullYear() === year)
    const totalPaid = totals.reduce((sum, d) => sum + d.paid, 0)
    const totalPending = totals.reduce((sum, d) => sum + (d.value - d.paid), 0)
    return { totalPaid, totalPending }
  }

  console.log({monthlyData})

  useEffect(() => {
    if (isLoading || error || !monthlyData) return

    const chartInstance = createChart(chartContainerRef.current, { 
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        background: {
          type: ColorType.Solid,
          color: darkMode ? '#2C3333' : '#F1F5F8',
        },
        textColor: darkMode ? '#D9D9D9' : '#42444c',
      },
      grid: {
        vertLines: {
          color: darkMode ? '#444444' : '#424e4211',
        },
        horzLines: {
          color: darkMode ? '#444444' : '#424e4211',
        },
      },
    })
    setChart(chartInstance)

    const paidSeries = chartInstance.addAreaSeries({
      lineColor: 'rgba(21, 115, 71, 1)', // Verde para lo pagado
      topColor: 'rgba(21, 115, 71, 0.8)',
      bottomColor: 'rgba(25, 135, 84, 0.01)',
      priceLineVisible: false,
    })

    const pendingSeries = chartInstance.addAreaSeries({
      lineColor: 'rgba(187, 43, 60, 1)', // Rojo para lo pendiente
      topColor: 'rgba(187, 43, 60, 0.8)',
      bottomColor: 'rgba(255, 0, 0, 0.01)',
      priceLineVisible: false,
    })

    setAreaSeriesPaid(paidSeries)
    setAreaSeriesPending(pendingSeries)

    chartInstance.subscribeCrosshairMove((param) => {
      if (!param || !param.time) {
        tooltipRef.current.style.display = 'none'
        return
      }

      const { formattedDate, year } = formatDate(param.time) // Obtenemos la fecha formateada y el año
      const { totalPaid, totalPending } = calculateTotalsByYear(year)

      const paidValue = param.seriesData.get(paidSeries)?.value || 0
      const pendingValue = param.seriesData.get(pendingSeries)?.value || 0

      tooltipRef.current.style.display = 'block'
      tooltipRef.current.innerHTML = `
        <strong>${formattedDate}</strong><br>
        <span style="margin-left: 10px">Pagado: ${paidValue.toFixed(2).replace('.00', '')} €</span><br>
        <span style="margin-left: 10px">Pendiente: ${pendingValue.toFixed(2).replace('.00', '')} €</span><br>
        <span style="margin-left: 10px">Total: <strong>${(paidValue + pendingValue).toFixed(2).replace('.00', '')} €</strong></span><br>
        <strong>Total ${year}:</strong><br>
        <span style="margin-left: 10px">Pagado: ${totalPaid.toFixed(2).replace('.00', '')} €</span><br>
        <span style="margin-left: 10px">Pendiente: ${totalPending.toFixed(2).replace('.00', '')} €</span><br>
        <span style="margin-left: 10px">Total: <strong>${(totalPaid + totalPending).toFixed(2).replace('.00', '')} €</strong></span>
      `

      const chartContainerRect = chartContainerRef.current.getBoundingClientRect()
      tooltipRef.current.style.left = `${chartContainerRect.left + param.point.x + 15}px`
      tooltipRef.current.style.top = `${chartContainerRect.top + param.point.y + 15}px`
    })

    return () => {
      chartInstance.remove()
    }
    // eslint-disable-next-line
  }, [darkMode, monthlyData, isLoading, error])

  useEffect(() => {
    if (areaSeriesPaid && areaSeriesPending && monthlyData) {
      const paidData = monthlyData.map(d => ({ time: d.time, value: d.paid }))
      const pendingData = monthlyData.map(d => ({ time: d.time, value: d.value - d.paid }))

      areaSeriesPaid.setData(paidData)
      areaSeriesPending.setData(pendingData)

      chart.timeScale().fitContent()
    }
  }, [areaSeriesPaid, areaSeriesPending, chart, monthlyData])

  if (isLoading) return <div>Cargando...</div>
  if (error) return <div>Error al cargar los datos</div>

  return (
    <>
      <Row className="mx-sm-5 mx-2 my-2">
        <Col lg={12}>
          <h5>Ingresos mensuales</h5>
        </Col>
      </Row>
      <Row className="justify-content-md-center mx-3 mt-3">
        <Col md={12} className="justify-content-center">
          <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }}></div>
          <div ref={tooltipRef} style={tooltipStyle}></div>
        </Col>
      </Row>
    </>
  )
}

const tooltipStyle = {
  position: 'absolute',
  backgroundColor: '#FFFFFF',
  color: '#000000',
  padding: '5px',
  borderRadius: '5px',
  fontSize: '12px',
  display: 'none',
  pointerEvents: 'none',
  zIndex: 1000,
}
