import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { useAuth } from './contexts/AuthContext'
import Login from './components/login/Login'
import BPNavbar, { PublicNavbar } from './components/navbar/Navbar'
import Home from './views/home/home'
import Notes from './views/notes/notes'
import Patients from './views/patients/patients'
import Payments from './views/payments/payments'
import Server from './views/server/server'
import Sessions from './views/sessions/sessions'
import WaitSession, { WaitExpressSession } from './views/sessions/wait'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const queryClient = new QueryClient()

function App() {
  const [darkMode, setDarkMode] = useState(true)
  const { isLogged, user } = useAuth()

  if (!isLogged || (!user.username && !user.password)) {
    return (
      <div className="App">
        <Router>
          <Container fluid>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route
                  path="/join/:sessionId"
                  element={
                    <>
                      <Row>
                        <PublicNavbar
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      </Row>
                      <div style={{ marginTop: '70px' }}>
                        <WaitSession />
                      </div>
                    </>
                  }
                />
                <Route
                  path="/express-join/:sessionId"
                  element={
                    <>
                      <Row>
                        <PublicNavbar
                          darkMode={darkMode}
                          setDarkMode={setDarkMode}
                        />
                      </Row>
                      <div style={{ marginTop: '70px' }}>
                        <WaitExpressSession />
                      </div>
                    </>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Container>
                      <Login darkMode={darkMode} setDarkMode={setDarkMode} />
                    </Container>
                  }
                />
              </Routes>
            </QueryClientProvider>
          </Container>
        </Router>
      </div>
    )
  }

  return (
    <div className="App">
      <Router>
        <Container fluid>
          <Row>
            <BPNavbar darkMode={darkMode} setDarkMode={setDarkMode} />
          </Row>
          <QueryClientProvider client={queryClient}>
            <div style={{ marginTop: '70px' }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/join/:sessionId" element={<WaitSession />} />
                <Route path="/sessions" element={<Sessions />} />
                <Route path="/patients" element={<Patients />} />
                <Route path="/payments" element={<Payments darkMode={darkMode} />} />
                <Route path="/notes" element={<Notes />} />
                <Route path="/server" element={<Server />} />
              </Routes>
            </div>
          </QueryClientProvider>
        </Container>
      </Router>
    </div>
  )
}

export default App
